<script lang="ts" setup>
import { default as PButton } from 'primevue/button'
import { computed } from 'vue'
import EBackButton from '@/components/elements/buttons/EBackButton.vue'
import ENavigationButton from '@/components/elements/buttons/ENavigationButton.vue'

const props = withDefaults(
  defineProps<{
    currentPage: number
    totalPages: number
    canNext: boolean
    untilTimeInSecs: number
    completed: number[]
  }>(),
  {
    currentPage: 1,
    totalPages: 1,
    canNext: false,
    untilTimeInSecs: 0
  }
)
const emits = defineEmits(['previous', 'next'])

function handlePrevious() {
  if (props.currentPage > 1) {
    emits('previous')
  }
}

function handleNext() {
  if (props.canNext) {
    emits('next')
  }
}

function showPrevious() {
  return props.currentPage > 1
}

function showNext() {
  return props.currentPage < props.totalPages
}

const displayUntilTimeMsg = computed(() => {
  if (!props.canNext && (!props.untilTimeInSecs || props.untilTimeInSecs === 0)) {
    return `Clique sur "C'est parti !" pour commencer !`
  } else if (props.untilTimeInSecs > 0) {
    const minutes = Math.floor(props.untilTimeInSecs / 60)
    const secondes = props.untilTimeInSecs - minutes * 60
    return `Temps restant avant de débloquer la suite : ${minutes < 10 ? '0' : ''}${minutes}:${
      secondes < 10 ? '0' : ''
    }${secondes}`
  } else {
    return `Tu peux passer à la suite !`
  }
})
</script>

<template>
  <div class="_m-PageNavigator p-4">
    <e-navigation-button :action="handlePrevious" icon="BackArrow" class="w-10" />
    <div class="_m-PageNavigator-Items">
      <span
        v-for="page in totalPages"
        :key="`page_${page}`"
        :class="[
          '_m-PageNavigator-Item',
          { active: page === currentPage },
          completed.includes(page) ? '_m-PageNavigator-Item--completed' : ''
        ]"
      />
    </div>
    <e-navigation-button icon="NextArrow" :disabled="!canNext" :action="handleNext" class="w-10" />
  </div>
</template>

<style lang="scss">
.p-tooltip.custom-tooltip.p-tooltip-top {
  min-width: fit-content;
}

.p-tooltip.custom-tooltip.p-tooltip-top .p-tooltip-text {
  background-color: var(--primary-night-blue) !important;
  color: var(--secondary);
  text-align: left;
}

.p-tooltip.custom-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: var(--primary-night-blue) !important;
}
</style>

<style scoped lang="scss">
._m-PageNavigator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  color: var(--color-white);
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.1rem;

  ._m-PageNavigator-Items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 0.5rem;
    position: relative;
    margin: 0 0.5rem;
    max-width: calc(100% - 6rem);
  }

  ._m-PageNavigator-Item {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: var(--variant-white);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    position: relative;

    &--completed {
      background-color: var(--primary-purple);
    }

    &.active {
      justify-content: center;
      align-items: center;
      background-color: var(--variant-white);
      opacity: 1;
    }
  }
}
</style>
