import API from '@/services/API'
import { NabooError } from '@/assets/ts/classes/Error'
import * as Sentry from '@sentry/vue'
import { SentryExceptionDto } from '@/assets/ts/DTO/sentry.dto'
import {
  GetMeResponseDto,
  GetPointsResponseDto
} from '@/assets/ts/DTO/profile/profile.response.dto'
import { PutTrackingConsentBannerDto } from '@/assets/ts/DTO/profile/profile.dto'

export const profile = {
  async getMe() {
    try {
      const response = await API().get('profile/me')
      return Promise.resolve(new GetMeResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/profile.ts - profile.getMe')
        error.setMessage('Une erreur est survenue lors de la récupération de votre profile.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération de votre profile.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(new SentryExceptionDto({ nabooError }))
      return Promise.reject(nabooError)
    }
  },

  async putTrackingConsentBanner() {
    try {
      const res = await API().put(
        'profile/tracking-consent-banner',
        new PutTrackingConsentBannerDto()
      )
      return Promise.resolve(new GetMeResponseDto(res.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/profile.ts - profile.putTrackingConsentBanner')
        error.setMessage('Une erreur est survenue lors de la mise à jour de votre consentement.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la mise à jour de votre consentement.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(new SentryExceptionDto({ nabooError }))
      return Promise.reject(nabooError)
    }
  }
}
