<script setup lang="ts">
import { default as PSkeleton } from 'primevue/skeleton'
import { default as PTag } from 'primevue/tag'
import EProgressBar from '@/components/elements/progressBar/EProgressBar.vue'
import MStarGrades from '@/components/modules/grades/MStarGrades.vue'
import ENavigationButton from '@/components/elements/buttons/ENavigationButton.vue'
import type { IScore } from '@/assets/ts/types/progress/Progress'
import { useRoute, useRouter } from 'vue-router'
import { useHistoryStore } from '@/stores/navigationHistory'

const router = useRouter()
const route = useRoute()
const { getPreviousRoute } = useHistoryStore()

defineProps<{
  missionLabel: string
  title: string
  taskCount: number
  tasksCompletedCount: number
  missionScore: IScore
  loading: boolean
}>()

const navigateBack = () => {
  if (getPreviousRoute) router.push(getPreviousRoute)
  else
    router.push({
      name: 'program'
    })
}
</script>

<template>
  <div class="bg-primary-night-blue w-full p-3">
    <div class="flex justify-between items-center pb-3">
      <e-navigation-button icon="BackArrow" :action="navigateBack" />
      <p-skeleton v-if="loading" width="8rem" height="1.5rem" border-radius="100px" />
      <p-tag
        v-else
        class="uppercase font-secondary font-medium text-orange-900 bg-orange-300 px-2 py-0"
        rounded
      >
        {{ missionLabel }}
      </p-tag>

      <div style="width: 45px" />
    </div>

    <div class="flex items-center gap-1">
      <div class="flex-1">
        <p-skeleton v-if="loading" width="10rem" />
        <h3 v-else class="font-bold">{{ title }}</h3>

        <p-skeleton v-if="loading" class="mt-2" border-radius="100px" />
        <div class="flex items-center" v-if="!loading && taskCount !== tasksCompletedCount">
          <e-progress-bar :max="taskCount" :value="tasksCompletedCount">
            <template #content>
              <p class="whitespace-nowrap ml-2">{{ tasksCompletedCount }}/{{ taskCount }} tâches</p>
            </template>
          </e-progress-bar>
        </div>
      </div>
      <div v-if="!loading && taskCount === tasksCompletedCount">
        <m-star-grades :points="missionScore.score" color="blue" size="small" />
      </div>
    </div>
  </div>
</template>
