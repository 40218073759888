<script setup lang="ts">
import MissionResultHeader from '@/views/results/blocks/MissionResultHeader.vue'
import BOrbits from '@/components/backgrounds/BOrbits.vue'
import MGradeCircle from '@/components/modules/grades/MGradeCircle.vue'
import { default as PTag } from 'primevue/tag'
import EIcon from '@/components/elements/icons/EIcon.vue'
import MStarGrades from '@/components/modules/grades/MStarGrades.vue'
import EImproveScoreButton from '@/components/elements/buttons/EImproveScoreButton.vue'
import MissionResultDetails from '@/views/results/blocks/MissionResultDetails.vue'

const missionLabel = 'Mission 1'
const title = 'Résultat de la mission'
</script>

<template>
  <b-orbits />
  <mission-result-header :missionLabel="missionLabel" :title="title" />

  <div
    :class="$style.VResults"
    class="relative w-full h-full px-4 max-w-screen-sm mx-auto overflow-auto pb-4 pt-6 flex flex-col items-center gap-6"
    v-if="!loading"
  >
    <div class="flex flex-col items-center gap-4">
      <h2 class="brand-heading medium uppercase">Mission terminée</h2>
    </div>

    <mission-result-details :misionScorePerfect="true" />
    <div class="grid grid-cols-1 gap-4">
      <p>Envie de faire encore mieux ?</p>
      <e-improve-score-button />
    </div>
  </div>
</template>

<style module>
.VResults {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: var(--linear-02);
    z-index: -1;
  }
}
</style>
