<script lang="ts" setup>
import { default as PImage } from 'primevue/image'
import { onMounted, ref } from 'vue'
import DonutChart from '@/components/elements/charts/DonutChart.vue'
import EIcons from '@/components/elements/icons/EIcons.vue'
import CheckUrl from '@/assets/images/check.png'
import RunningUrl from '@/assets/images/running.png'

defineProps<{
  percentage: number
  completed: number
  inProgress: number
}>()

onMounted(function () {
  const donutCharts = document.querySelectorAll('.donut-chart')

  donutCharts.forEach((chart) => {
    const circle = chart.querySelector('.circle') as SVGCircleElement
    const radius = circle.r.baseVal.value
    const circumference = 2 * Math.PI * radius
    const percentage = parseInt(chart.dataset.percentage)

    circle.style.strokeDasharray = `${circumference} ${circumference}`
    circle.style.strokeDashoffset = `${circumference}`

    const offset = circumference - (percentage / 100) * circumference
    circle.style.strokeDashoffset = `${offset}`
  })
})
</script>

<template>
  <e-icons :load="['Check', 'Running']" />
  <div class="relative">
    <div class="rounded bg-blue-800 py-3 px-2 shadow-2xl">
      <div class="flex justify-between items-center">
        <div class="flex gap-2">
          <donut-chart :percentage="percentage" class="w-12 h-12" />
          <div class="flex flex-col justify-center">
            <p class="text small">tu as complété</p>
            <p class="font-bold text small">{{ percentage }}% du programme</p>
          </div>
        </div>

        <div class="flex flex-col gap-1 items-center border-l pl-2">
          <p-image :src="CheckUrl" />
          <p class="text x-small">Terminées</p>
          <div class="font-secondary font-bold">{{ completed }}</div>
        </div>
        <div class="flex flex-col gap-1 items-center">
          <p-image :src="RunningUrl" />
          <p class="text x-small">En cours</p>
          <div class="font-secondary font-bold">{{ inProgress }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
