import type { ILearningSupport } from '@/assets/ts/types/learning/learningSupports'

export class Support implements ILearningSupport {
  supportId: number
  supportOrder: number
  supportUrl: string
  grainId: number
  grainTitle: string
  estimatedTime: number
  isWatched: boolean

  constructor(obj: ILearningSupport) {
    this.supportId = obj.supportId
    this.supportOrder = obj.supportOrder
    this.supportUrl = obj.supportUrl
    this.grainId = obj.grainId
    this.grainTitle = obj.grainTitle
    this.estimatedTime = obj.estimatedTime
    this.isWatched = obj.isWatched
  }
}
