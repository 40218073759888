import { Quiz } from '@/assets/ts/classes/progress/quiz'
import type { IQuiz } from '@/assets/ts/types/progress/Quiz'
import type { ILearningModule } from '@/assets/ts/types/learning/learningModule'
import type { ILearningCourse } from '@/assets/ts/types/learning/learningCourse'
import type { ILearningGrain } from '@/assets/ts/types/learning/learningGrain'
import type { IProgressMission, IProgressQuiz, IScore } from '@/assets/ts/types/progress/Progress'
import type { ILearningSupport } from '@/assets/ts/types/learning/learningSupports'
import { Support } from '@/assets/ts/classes/progress/support'

export class getProgramResponseDto {
  explorations: getLearningCourseResponseDto[]

  constructor(courses: getLearningCourseResponseDto[]) {
    this.explorations = courses
  }
}

export class getLearningCourseResponseDto {
  courseId: number
  courseTitle: string
  courseOrder: number
  courseName: string
  quizzes: IProgressQuiz[]
  learningModules: ILearningModule[]

  constructor(
    courseId: number,
    courseTitle: string,
    courseOrder: number,
    courseName: string,
    quizzes: IProgressQuiz[],
    learningModules: ILearningModule[]
  ) {
    this.courseId = courseId
    this.courseTitle = courseTitle
    this.courseOrder = courseOrder
    this.courseName = courseName
    this.quizzes = quizzes
    this.learningModules = learningModules
  }
}

export class getLearningModuleResponseDto implements ILearningModule {
  moduleId: number
  moduleTitle: string
  moduleOrder: number
  moduleScore: IScore
  countCompletedLearningGrain: number
  countTotalLearningGrain: number
  grains: ILearningGrain[]

  constructor(mission: ILearningModule) {
    this.moduleId = mission.moduleId
    this.moduleTitle = mission.moduleTitle
    this.moduleOrder = mission.moduleOrder
    this.moduleScore = mission.moduleScore
    this.countCompletedLearningGrain = mission.countCompletedLearningGrain
    this.countTotalLearningGrain = mission.countTotalLearningGrain
    this.grains = mission.grains
  }
}

export class getLearningGrainResponseDto implements ILearningGrain {
  grainId: number
  grainTitle: string
  grainOrder: number
  grainScore: IScore
  preQuizId: number
  preQuizCompleted: boolean
  postQuizId: number
  isStarted: boolean
  isCompleted: boolean
  estimatedTime: number
  supports: ILearningSupport[]

  constructor(grain: ILearningGrain) {
    this.grainId = grain.grainId
    this.grainTitle = grain.grainTitle
    this.grainOrder = grain.grainOrder
    this.grainScore = grain.grainScore
    this.preQuizId = grain.preQuizId
    this.preQuizCompleted = grain.preQuizCompleted
    this.postQuizId = grain.postQuizId
    this.isStarted = grain.isStarted
    this.isCompleted = grain.isCompleted
    this.estimatedTime = grain.estimatedTime
    this.supports = grain.supports.map((support, i) => {
      const s = new Support(support)
      if (s.supportOrder === 0) s.supportOrder = i + 1

      return s
    })
  }
}
