<script lang="ts" setup>
import { default as PButton } from 'primevue/button'
import { default as PChip } from 'primevue/chip'
import { computed, useCssModule } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useEMethodosStore } from '@/stores/emethodos'
import { updateCurrentUrlPath } from '@/router/utils'
import VideoItem from '@/views/emethodos/blocks/VideoItem.vue'

// Router
const route = useRoute()
const router = useRouter()

// Styles
const styles = useCssModule()

// Store
const { setCurrentVideo, resetCurrentVideo } = useEMethodosStore()
const { getVideo, getVideos } = storeToRefs(useEMethodosStore())

//Computed
const getVideoUrl = computed(() => {
  return getVideo.value?.url
})

const getVideoTags = computed(() => {
  return getVideo.value?.tags
})

const getVideosWithSameTags = computed(() => {
  // return only 3 random values from the list but not the current video
  return getVideos.value
    .filter((video) => {
      return (
        video.tags.some((tag) => getVideoTags.value?.includes(tag)) &&
        video.id !== getVideo.value?.id
      )
    })
    .sort(() => Math.random() - 0.5)
    .slice(0, 3)
})

// Methods
function selectVideo(videoId: string) {
  setCurrentVideo(videoId)
  updateCurrentUrlPath(router, route)

  document.querySelector(`.${styles['v-emethodo']}`)?.scrollTo(0, 0)
}

async function back() {
  await router.push({ name: 'contents' })
  resetCurrentVideo()
}
</script>

<template>
  <div
    :class="$style['v-emethodo']"
    class="flex-col items-center p-2 overflow-auto lg:p-20"
  >
    <p-button
      icon="pi pi-arrow-left"
      iconPos="left"
      label="Retour à la liste"
      class="mb-8"
      @click="back()"
      rounded
      outlined
      severity="secondary"
      style="border-color: #fff; color: #fff"
    />
    <div :class="$style['v-emethodo-content']" class="relative">
      <iframe
        :src="getVideoUrl"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        class="w-full flex-1"
      ></iframe>

      <div class="justify-center px-8 py-4" :class="$style['video-footer']">
        <h5>{{ getVideo?.title }}</h5>
        <div class="w-full" :class="$style['Categories']">
          <div class="flex items-center">
            <p-chip
              v-for="(keyword, i) in getVideo?.tags"
              :key="`keyword_${i}`"
              class="m-2 top-0 left-0 whitespace-nowrap overflow-hidden overflow-ellipsis"
              :label="keyword"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-20">
      <h4>Découvrir plus de vidéos</h4>

      <div class="grid grid-cols-12 gap-4 mt-4 w-full" :class="$style['VideosGrid']">
        <video-item
          v-for="video in getVideosWithSameTags"
          :key="video.id"
          :video="video"
          class="col-span-12 md:col-span-4"
          @click="selectVideo(video.id)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.v-emethodo {
  height: 100%;
  background: url('@/assets/images/backgroundAssets/general/Dark_01.svg');
  background-size: cover;
  color: var(--variant-white);
  overflow: auto;

  &-content {
    width: 100%;
    border-radius: 1.25rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    iframe {
      width: 100%;
      flex-grow: 1;
      aspect-ratio: 16/9;
    }

    .video-footer {
      width: 100%;
      background-color: var(--primary-dark-blue);
      color: var(--variant-white);
    }
  }

  .VideoItem {
    transition: all ease 0.3s;

    img {
      transition: all ease 0.3s;
    }

    .VideoItemButton {
      opacity: 0;
      transition: all ease 0.3s;
      transform: translate(-50%, -50%);
    }

    &:hover {
      img {
        filter: grayscale(0.5);
        transform: scale(1.1);
      }

      .VideoItemButton {
        opacity: 1;
      }
    }
  }

  .Categories {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
</style>
