import type { getLearningCourseResponseDto } from '@/assets/ts/DTO/trainings/program.response.dto'
import type { ILearningCourse } from '@/assets/ts/types/learning/learningCourse'
import { Quiz } from '@/assets/ts/classes/progress/quiz'
import { Mission } from '@/assets/ts/classes/progress/mission'
import type { IProgressQuiz } from '@/assets/ts/types/progress/Progress'

export class Exploration {
  courseId: number
  title: string
  order: number
  name: string
  quizzes: IProgressQuiz[]
  missions: Mission[]

  constructor(course: getLearningCourseResponseDto) {
    this.courseId = course.courseId
    this.title = course.courseTitle
    this.order = course.courseOrder
    this.quizzes = course.quizzes
    this.missions = course.learningModules.map((mission) => new Mission(mission))
    this.name = course.courseName
  }

  get hasTestToPass() {
    return this.quizzes.some((quiz) => quiz.quizzesId.length > 0)
  }
}
