// Sentry DTO

import type { NabooError } from '@/assets/ts/classes/Error'

export class SentryExceptionDto {
  constructor(
    public exception: {
      error?: unknown
      nabooError?: NabooError
    }
  ) {}
}
