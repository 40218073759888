<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  name: string
  type: string
}>()

const capitalizedName = computed(() => props.name.charAt(0).toUpperCase() + props.name.slice(1))
</script>

<template>
  <div
    v-if="type === 'svg'"
    :id="capitalizedName + '-Container'"
    class="_e-Icon _e-Icon--svg"
    :class="[$style.EIcon, $style['EIcon--svg']]"
  >
    <svg :id="capitalizedName + '-Svg'" :class="$style.ESvg">
      <use :xlink:href="'#' + capitalizedName" />
    </svg>
    <slot />
  </div>
  <i v-else :class="name"> </i>
</template>

<style module>
._e-Icon-Container {
  --iconWidth: 100%;
  --iconHeight: auto;

  width: var(--iconWidth);
  height: var(--iconHeight);
}

.EIcon {
  width: 100%;

  &.EIcon--svg {
    --svgWidth: 100%;
    --svgPaddingTop: 100%;

    position: relative;
    top: 0;
    left: 0;
    width: var(--svgWidth);
    height: 0;
    padding-top: var(--svgPaddingTop);
    overflow: hidden;
    display: block;
    margin: auto;

    .ESvg {
      transform: none;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      fill: var(--fill);
    }
  }

  &._e-Icon--variantPrimary {
    --fill: #{$primary_color};
  }

  &._e-Icon--variantSecondary {
    --fill: #{$secondary_color};
  }
}
</style>
