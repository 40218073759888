import { defineStore } from 'pinia'
import { type Ref, ref } from 'vue'
import type { IXAPIStatementType } from '@/assets/ts/types/xAPI/xAPI'
import nabooApi from '@/services/nabooApi'
import { NabooError } from '@/assets/ts/classes/Error'

export const usexAPIStore = defineStore('xAPI', () => {
  // STATE
  const xAPIQueue: Ref<IXAPIStatementType[]> = ref([])
  const xAPIMMessagesError: Ref<IXAPIStatementType[]> = ref([])
  const isProcessing: Ref<boolean> = ref(false)
  const retryCount: Ref<number> = ref(0)

  // MUTATIONS
  async function addToQueue(statement: IXAPIStatementType) {
    xAPIQueue.value.push(statement)
    if (!isProcessing.value) {
      await processQueue()
    }
  }

  function removeFromQueue() {
    xAPIQueue.value.shift()
    retryCount.value = 0 // Reset retry count on successful processing
  }

  // Process the queue while there are statements in it
  async function processQueue() {
    if (xAPIQueue.value.length === 0) return

    retryCount.value++
    isProcessing.value = true

    // Process the statement
    try {
      await nabooApi.sendxAPIStatement(xAPIQueue.value[0])
      removeFromQueue()
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        nabooError = error
        xAPIMMessagesError.value.push(xAPIQueue.value[0])
        removeFromQueue()
        isProcessing.value = false
      } else {
        nabooError = new NabooError({
          message: "Une erreur est survenue lors de l'envoi des données.",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      if (nabooError.getCode() === 'ERR_NETWORK' && retryCount.value < 3) {
        setTimeout(() => {
          processQueue()
        }, 5000)
        return
      }
    }

    isProcessing.value = false
    // Process the next statement if queue is not empty
    if (xAPIQueue.value.length > 0) {
      await processQueue()
    }
  }

  return {
    xAPIQueue,
    xAPIMMessagesError,
    isProcessing,
    addToQueue,
    removeFromQueue
  }
})
