import type { ILearningModule } from '@/assets/ts/types/learning/learningModule'
import { Task } from '@/assets/ts/classes/progress/task'
import type { IScore } from '@/assets/ts/types/progress/Progress'
import type { ILearningGrain } from '@/assets/ts/types/learning/learningGrain'

export class Mission {
  title: string
  moduleId: number
  order: number
  score: IScore
  tasks: Task[]
  countCompletedTasks: number
  countTasks: number

  constructor(obj: ILearningModule) {
    this.title = obj.moduleTitle

    this.order = obj.moduleOrder
    this.tasks = obj.grains.map((task) => new Task(task))
    this.moduleId = obj.moduleId
    this.countCompletedTasks = obj.countCompletedLearningGrain
    this.countTasks = obj.countTotalLearningGrain
    this.score = obj.moduleScore
  }

  get taskCount() {
    return this.tasks.length
  }

  get completedTasksLength() {
    return this.tasks.filter((task) => task.isCompleted).length
  }

  get isStarted() {
    return this.tasks.some((task) => task.isStarted)
  }

  get isCompleted() {
    return this.countCompletedTasks === this.countTasks
  }

  get estimatedTime() {
    return this.tasks.reduce((acc, task) => acc + task.estimatedTime, 0)
  }

  get nextTask() {
    return this.tasks.find((task) => !task.isCompleted)
  }

  getTaskByQuizId(quizId: number): Task {
    return (
      this.tasks.find((task) => task.preQuizId === quizId || task.postQuizId === quizId) ||
      new Task({} as ILearningGrain)
    )
  }

  getNextTaskByTaskId(grainId: number): Task {
    const taskIndex = this.tasks.findIndex((task) => task.grainId === grainId)
    return this.tasks[taskIndex + 1]
  }

  isTaskLast(grainId: number): boolean {
    return this.tasks[this.tasks.length - 1].grainId === grainId
  }

  completeMission() {
    let score = 0
    this.tasks.forEach((task) => {
      score += task.taskScore.score
    })

    this.score.score = score / this.countTasks
  }
}
