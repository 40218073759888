import { defineStore } from 'pinia'
import { computed, type Ref, ref } from 'vue'

export const useHistoryStore = defineStore('history', () => {
  const history: Ref<string[]> = ref([])

  function addToHistory(route: string) {
    if (history.value[history.value.length - 1] === route) return
    history.value.push(route)
  }

  const getLatestRoute = computed(() => history.value[history.value.length - 1])
  const getPreviousRoute = computed(() => history.value[history.value.length - 2])

  return { history, addToHistory, getLatestRoute, getPreviousRoute }
})
