import API from '@/services/API'
import { GetLogbookInfosResponseDto } from '@/assets/ts/DTO/logbook/logbook.response.dto'
import { NabooError } from '@/assets/ts/classes/Error'
import * as Sentry from '@sentry/vue'

export const logbook = {
  async getLogbookInfos() {
    try {
      const response = await API().get('/profile/logbook')
      return Promise.resolve(new GetLogbookInfosResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/logbook - getLogbookInfos')
        error.setMessage('Une erreur est survenue lors de la récupération des données.')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération des données.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      Sentry.captureException(nabooError)
      return Promise.reject(nabooError)
    }
  }
}
