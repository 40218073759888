export namespace LearningEnums {
  export enum Status {
    DRAFT = 'DRAFT',
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    DELETED = 'DELETED'
  }

  export enum SupportType {
    GENIALLY = 'GENIALLY'
  }

  export enum QuizType {
    PRE = 'PRE',
    POST = 'POST',
    DIAG = 'DIAG'
  }

  export enum QuestionType {
    SINGLE_CHOICE = 'SINGLE_CHOICE',
    MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
    TRUE_FALSE = 'TRUE_FALSE',
    MATCHING = 'MATCHING',
    SEQUENCING = 'SEQUENCING'
  }

  export function isValidQuizType(value: string): value is QuizType {
    return Object.values(QuizType).includes(value as QuizType)
  }

  export function statusLabel(value: Status): string {
    switch (value) {
      case Status.DRAFT:
        return 'Brouillon'
      case Status.ACTIVE:
        return 'Actif'
      case Status.ARCHIVED:
        return 'Archivé'
      case Status.DELETED:
        return 'Supprimé'
    }
  }

  export function statusColor(value: Status): string {
    switch (value) {
      case Status.DRAFT:
        return 'secondary'
      case Status.ACTIVE:
        return 'success'
      case Status.ARCHIVED:
        return 'danger'
      case Status.DELETED:
        return 'danger'
    }
  }

  export function questionTypeLabel(value: QuestionType): string {
    switch (value) {
      case QuestionType.SINGLE_CHOICE:
        return 'QCU'
      case QuestionType.MULTIPLE_CHOICE:
        return 'QCM'
      case QuestionType.TRUE_FALSE:
        return 'Vrai/Faux'
      case QuestionType.MATCHING:
        return 'Association'
      case QuestionType.SEQUENCING:
        return 'Réordonner'
    }
  }
}
