import type { IProgressQuestion, IQuiz } from '@/assets/ts/types/progress/Quiz'
import { LearningEnums } from '@/assets/ts/types/learning/enums'

export class GetDiagnosticQuizzesFromCourseSlugResponseDto {
  quizzes: IQuiz[]

  constructor(data: IQuiz[]) {
    this.quizzes = data
  }
}

export class GetQuizResponseDto implements IQuiz {
  quizId: number
  quizType: LearningEnums.QuizType
  moduleTitle: string
  moduleName: string
  courseId: number
  moduleId: number | null
  score: number | null
  scoreMin: number | null
  scoreMax: number | null
  scaled: number | null
  duration: {
    seconds: number | null
    iso8601: string | null
  }
  startTime: string | null // Assuming ISO 8601 date-time string
  endTime: string | null // Assuming ISO 8601 date-time string
  uuidAttemptSession: string | null
  questions: IProgressQuestion[]
  isCompleted: boolean

  constructor(data: IQuiz) {
    this.quizId = data.quizId
    this.quizType = data.quizType
    this.moduleTitle = data.moduleTitle
    this.moduleName = data.moduleName
    this.courseId = data.courseId
    this.moduleId = data.moduleId
    this.score = data.score
    this.scoreMin = data.scoreMin
    this.scoreMax = data.scoreMax
    this.scaled = data.scaled
    this.duration = data.duration
    this.startTime = data.startTime
    this.endTime = data.endTime
    this.uuidAttemptSession = data.uuidAttemptSession
    this.questions = data.questions.sort((a, b) => a.order - b.order)
    this.isCompleted = data.isCompleted
  }
}
