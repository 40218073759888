import API from '@/services/API'

export const emethodos = {
  async getEmethodos() {
    return API().get('e-methodos/')
  },

  async getEmethodoIsEnabled() {
    return API().get('e-methodos/enabled')
  }
}
