import type { IXAPIStatementType } from '@/assets/ts/types/xAPI/xAPI'
import API from '@/services/API'
import { NabooError } from '@/assets/ts/classes/Error'

export const xAPI = {
  async sendxAPIStatement(statement: IXAPIStatementType) {
    try {
      await API().post(`xapi/statements`, statement)
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/xAPI - sendxAPIStatement')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: "Une erreur est survenue lors de l'envoi des données.",
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  }
}
