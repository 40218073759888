<script setup lang="ts">
import MissionHeader from '@/views/training/blocks/MissionHeader.vue'
import MissionTask from '@/views/training/blocks/MissionTask.vue'
import BOrbits from '@/components/backgrounds/BOrbits.vue'
import { default as PSkeleton } from 'primevue/skeleton'
import { useProgramStore } from '@/stores/program'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

const route = useRoute()

const loading = ref(true)
const { getProgram, getMission, getExplorationNameFromMissionId } = useProgramStore()
const { mission } = storeToRefs(useProgramStore())

onMounted(async () => {
  await getProgram()
  await getMission(Number(route.params.courseId), Number(route.params.moduleId))

  loading.value = false
})

const missionLabel = computed(() => {
  if (mission.value.order < 10) {
    return `Mission ${getExplorationNameFromMissionId(mission.value.moduleId)}-0${mission.value.order}`
  } else {
    return `Mission ${getExplorationNameFromMissionId(mission.value.moduleId)}-${mission.value.order}`
  }
})
</script>
<template>
  <div class="w-full h-full overflow-auto" :class="$style.VMission">
    <b-orbits />
    <mission-header
      :title="mission.title"
      :task-count="mission.countTasks"
      :tasks-completed-count="mission.countCompletedTasks"
      :mission-label="missionLabel"
      :mission-score="mission.score"
      :loading="loading"
    />

    <div class="flex flex-col gap-3 p-6 pt-5">
      <template v-if="loading">
        <mission-task v-for="i in 4" :key="i" :is-loading="true" :task="null" :is-active="false" />
      </template>
      <template v-else>
        <mission-task
          v-for="(task, i) in mission.tasks"
          :key="i"
          :is-active="mission.nextTask?.order === i + 1"
          :task="task"
          :disabled="mission.nextTask && i + 1 > mission.nextTask.order"
        />
      </template>
    </div>
  </div>
</template>

<style module>
.VMission {
  background: var(--linear-02);
}
</style>
