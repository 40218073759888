import type { IQuiz } from '@/assets/ts/types/progress/Quiz'
import { DateTime } from 'ts-luxon'
import { GetQuizResponseDto } from '@/assets/ts/DTO/quiz/quiz.response.dto'

export class Quiz extends GetQuizResponseDto {
  order?: number
  constructor(data: IQuiz, order?: number) {
    super(data)
    this.order = order
  }

  get formattedStartTime() {
    return DateTime.fromISO(<string>this.startTime).toLocaleString(DateTime.DATETIME_FULL)
  }

  get formattedEndTime() {
    return DateTime.fromISO(<string>this.endTime).toLocaleString(DateTime.DATETIME_FULL)
  }

  get formattedDuration() {
    return DateTime.fromISO(<string>this.duration.iso8601).toFormat('hh:mm:ss')
  }

  get lastUnansweredQuestion() {
    return this.questions.find((question) => !question.isAnswered)
  }

  get isQuizStarted() {
    return this.startTime !== null
  }

  get unansweredQuestions() {
    return this.questions.filter((question) => !question.isAnswered)
  }

  startQuiz(timestamp: string, attemptSessionUUID: string) {
    this.startTime = timestamp
    this.uuidAttemptSession = attemptSessionUUID
  }

  answerQuestion(questionId: number, score: number, uuidQuestion: string) {
    const questionIndex = this.questions.findIndex((question) => question.questionId === questionId)

    if (questionIndex !== -1) {
      this.questions[questionIndex].isAnswered = true
      this.questions[questionIndex].score = score
      this.questions[questionIndex].uuidQuestion = uuidQuestion
      this.questions[questionIndex].scoreMin = 0
      this.questions[questionIndex].scoreMax = 1
      this.questions[questionIndex].scaled = score
    }
  }

  completeQuiz() {
    this.endTime = new Date().toISOString()
    this.duration.seconds = DateTime.fromISO(<string>this.endTime).diff(
      DateTime.fromISO(<string>this.startTime),
      'seconds'
    ).seconds
    this.duration.iso8601 = DateTime.fromISO(<string>this.endTime)
      .diff(DateTime.fromISO(<string>this.startTime))
      .toISO()
    this.score = this.questions.reduce((acc, question) => acc + (question.score || 0), 0)
    this.scoreMin = 0
    this.scoreMax = this.questions.length
    this.scaled = (this.score - this.scoreMin) / (this.scoreMax - this.scoreMin)
    this.isCompleted = true
  }

  getNextQuestion(questionOrder: number) {
    return this.questions.find((question) => question.order === questionOrder + 1)
  }
}
