<script setup lang="ts">
import { default as PTag } from 'primevue/tag'
import EImproveScoreButton from '@/components/elements/buttons/EImproveScoreButton.vue'
import MStarGrades from '@/components/modules/grades/MStarGrades.vue'
import { computed, useCssModule } from 'vue'

const styles = useCssModule()
const props = defineProps<{
  scoreIsPerfect?: boolean
}>()

const classes = computed(() => {
  const classes = []

  if (props.scoreIsPerfect) {
    classes.push(styles.hasPerfectScore)
  } else {
    classes.push('border border-solid border-white')
  }

  return classes
})
</script>

<template>
  <div
    class="grid grid-cols-1 gap-2 bg-surface-blue rounded-2xl p-4 relative"
    :class="[$style.MissionScoreBlock, classes]"
  >
    <div class="flex">
      <div>
        <p-tag
          class="uppercase font-secondary font-medium px-2 py-0"
          :class="[false ? 'bg-purple-300 text-purple-900' : 'bg-orange-300 text-orange-900 ']"
          rounded
        >
          Mission andromède-03
        </p-tag>
        <p class="text font-bold mt-1">Communiquer de façon constructive</p>
      </div>

      <m-star-grades size="small" :points="1" color="blue" />
    </div>

    <div class="flex justify-between items-center">
      <e-improve-score-button v-if="!scoreIsPerfect" />

      <a
        @click="$router.push({ name: 'missionResult', params: { missionId: 1 } })"
        class="text-white underline"
        >Voir le détail</a
      >
    </div>
  </div>
</template>

<style module>
.MissionScoreBlock.hasPerfectScore {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 0.5rem);
    height: calc(100% + 0.5rem);
    background: var(--linear-03);
    border-radius: 1rem;
    z-index: -1;
  }
}
</style>
