<script setup lang="ts">
import { LearningEnums } from '@/assets/ts/types/learning/enums'
import type { Task } from '@/assets/ts/classes/progress/task'
import { default as PButton } from 'primevue/button'
import { computed, onMounted, ref } from 'vue'
import MascotIntro from '@/assets/images/mascottes/MASCOTTE_Joie.svg'
import MascotIntroDanger from '@/assets/images/mascottes/MASCOTTE_Danger.svg'
import { useRoute, useRouter } from 'vue-router'
import type { Mission } from '@/assets/ts/classes/progress/mission'

const route = useRoute()
const router = useRouter()
const props = defineProps<{
  type: LearningEnums.QuizType
  task: Task
  mission: Mission
}>()

const showElements = ref([false, false, false, false])

onMounted(() => {
  displayElements()
})

const displayElements = () => {
  // set each element to true after a delay one by one

  setTimeout(() => {
    showElements.value[1] = true

    setTimeout(() => {
      showElements.value[2] = true

      setTimeout(() => {
        showElements.value[3] = true

        setTimeout(() => {
          showElements.value[4] = true
        }, 500)
      }, 500)
    }, 500)
  }, 500)
}

const contents = computed(() => {
  const contents = {
    label: {
      value: 'Bravo !',
      color: 'text-orange-400'
    },
    title: {
      value: 'Voyons ça de plus près en passant aux contenus suivants.'
    },
    button: {
      value: "C'est parti !",
      action: () =>
        router.push({
          name: 'task',
          params: {
            grainId: props.task.grainId.toString(),
            moduleId: route.params.moduleId,
            courseId: route.params.courseId
          }
        })
    },
    mascot: {
      value: MascotIntro
    }
  }

  return contents
})
</script>

<template>
  <div class="flex flex-col items-center mt-10 gap-4 max-w-screen">
    <transition name="slide-from-right">
      <h2
        v-if="showElements[1]"
        class="brand-heading uppercase text-center"
        :class="contents.label.color"
      >
        {{ contents.label.value }}
      </h2>
    </transition>

    <transition name="slide-from-right">
      <h2 v-if="showElements[2]" class="heading large text-center">
        {{ contents.title.value }}
      </h2>
    </transition>

    <transition name="slide-from-right">
      <p-button
        v-if="showElements[3]"
        :label="contents.button.value"
        severity="light"
        rounded
        class="mt-4"
        @click="contents.button.action"
      />
    </transition>

    <transition name="slide-from-bottom">
      <img v-if="showElements[4]" :src="contents.mascot.value" class="absolute bottom-0 max-h-96" />
    </transition>
  </div>
</template>

<style scoped>
.slide-from-right-enter-active,
.slide-from-right-leave-active {
  transition: all 0.3s ease;
}

.slide-from-right-enter-from {
  transform: translateX(1000px);
  opacity: 0;
}

.slide-from-right-leave-to {
  transform: translateX(-1000px);
  opacity: 0;
}

.slide-from-bottom-enter-active,
.slide-from-bottom-leave-active {
  transition: all 0.3s ease;
}

.slide-from-bottom-enter-from,
.slide-from-bottom-leave-to {
  transform: translateY(1000px);
  opacity: 0;
}
</style>
