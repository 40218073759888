import API from '@/services/API'
import { NabooError } from '@/assets/ts/classes/Error'
import {
  GetDiagnosticQuizzesFromCourseSlugResponseDto,
  GetQuizResponseDto
} from '@/assets/ts/DTO/quiz/quiz.response.dto'
import type { IAttemptedQuiz } from '@/assets/ts/types/xAPI/quiz'
import { Quiz } from '@/assets/ts/classes/progress/quiz'

export const quiz = {
  async getQuizzesFromCourse(courseSlug: string) {
    try {
      const response = await API().get(`profile/diagnostic/${courseSlug}`)

      return Promise.resolve(new GetDiagnosticQuizzesFromCourseSlugResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/profile/diagnostic - getQuizzesFromCourse')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération des quiz.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  },

  async getQuiz(quizId: number) {
    try {
      const response = await API().get(`profile/quiz/${quizId}`)
      return Promise.resolve(new GetQuizResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/profile/diagnostic - getQuiz')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération du quiz.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  }
}
