<script setup lang="ts">
import { default as PDialog } from 'primevue/dialog'
import { default as PButton } from 'primevue/button'
import type { Task } from '@/assets/ts/classes/progress/task'
import { useRoute, useRouter } from 'vue-router'
import EImproveScoreButton from '@/components/elements/buttons/EImproveScoreButton.vue'

const route = useRoute()
const router = useRouter()
const visible = defineModel('visible', { type: Boolean })

const props = defineProps<{
  task: Task
}>()

const navigateToQuiz = () => {
  router.push({
    name: 'quiz',
    params: {
      courseId: route.params.courseId,
      moduleId: route.params.moduleId,
      grainId: route.params.grainId,
      quizId: props.task.postQuizId
    }
  })
}

const navigateToTask = () => {
  router.push({
    name: 'task',
    params: {
      courseId: route.params.courseId,
      moduleId: route.params.moduleId,
      grainId: props.task.grainId
    }
  })
}
</script>

<template>
  <p-dialog v-model:visible="visible" modal closable :baseZIndex="10000" class="bg-blue-900 m-10">
    <div class="flex flex-col items-center gap-4">
      <p class="font-bold text-center">{{ task.title }}</p>

      <p class="text-center">Comment souhaites-tu améliorer ton résultat ?</p>

      <p-button label="Revoir les contenus" outlined severity="light" @click="navigateToTask" />
      <e-improve-score-button label="Refaire le quiz" :action="navigateToQuiz" />
    </div>
  </p-dialog>
</template>
