import { defineStore, storeToRefs } from 'pinia'
import { type Ref, ref } from 'vue'
import nabooApi from '@/services/nabooApi'
import { Exploration } from '@/assets/ts/classes/progress/exploration'
import { NabooError } from '@/assets/ts/classes/Error'
import * as Sentry from '@sentry/vue'
import { SentryExceptionDto } from '@/assets/ts/DTO/sentry.dto'
import { Mission } from '@/assets/ts/classes/progress/mission'
import { Task } from '@/assets/ts/classes/progress/task'
import { useProfileStore } from '@/stores/profile'
import { useAppStore } from '@/stores/app'
import { usexAPIStore } from '@/stores/xAPI'
import { xAPIMessageBuilder } from '@/assets/ts/classes/xApi'
import type { UUID } from '@/assets/ts/types/xAPI/xAPI'
import type { Support } from '@/assets/ts/classes/progress/support'

export const useProgramStore = defineStore('program', () => {
  const { me } = storeToRefs(useProfileStore())
  const { registrationCtx, appType } = storeToRefs(useAppStore())
  const { addToQueue } = usexAPIStore()

  const program: Ref<Exploration[]> = ref([])
  const mission: Ref<Mission> = ref({} as Mission)
  const taskContent: Ref<Task> = ref({} as Task)

  const getProgram = async () => {
    try {
      const response = await nabooApi.getCourses()

      program.value = response.explorations.map((course) => new Exploration(course))
      return Promise.resolve()
    } catch (error) {
      if (!(error instanceof NabooError)) Sentry.captureException(new SentryExceptionDto({ error }))
      return Promise.reject(error)
    }
  }

  const getMission = async (courseId: number, moduleId: number) => {
    try {
      const response = await nabooApi.getModule(courseId, moduleId)

      mission.value = new Mission(response)
      return Promise.resolve()
    } catch (error) {
      if (!(error instanceof NabooError)) Sentry.captureException(new SentryExceptionDto({ error }))
      return Promise.reject(error)
    }
  }

  const getTask = async (courseId: number, moduleId: number, grainId: number) => {
    if (!mission.value.moduleId) {
      await getMission(courseId, moduleId)
    }

    const task = await nabooApi.getGrain(courseId, moduleId, grainId)

    taskContent.value = new Task(task)
  }

  const startSupport = async (courseId: number, mission: Mission, task: Task, support: Support) => {
    const input = {
      actorUUID: me.value.identifier as UUID,
      courseId: courseId.toString(),
      contextRegistrationUUID: registrationCtx.value,
      platform: appType.value as 'web' | 'pwa',
      moduleId: mission.moduleId.toString(),
      grainId: task.grainId.toString(),
      supportId: support.supportId.toString()
    }

    const xAPIMessage = xAPIMessageBuilder.createInitializedLearningSupportMessage(input)

    await addToQueue(xAPIMessage)
  }

  const completeSupport = async (
    courseId: number,
    mission: Mission,
    task: Task,
    support: Support
  ) => {
    const input = {
      actorUUID: me.value.identifier as UUID,
      courseId: courseId.toString(),
      contextRegistrationUUID: registrationCtx.value,
      platform: appType.value as 'web' | 'pwa',
      moduleId: mission.moduleId.toString(),
      grainId: task.grainId.toString(),
      supportId: support.supportId.toString()
    }

    const xAPIMessage = xAPIMessageBuilder.createCompletedLearningSupportMessage(input)

    await addToQueue(xAPIMessage)
  }

  const startTask = async (courseId: number, mission: Mission, task: Task) => {
    if (task.order === 1) {
      await startMission(courseId, mission)
    }

    const input = {
      actorUUID: me.value.identifier as UUID,
      courseId: courseId.toString(),
      contextRegistrationUUID: registrationCtx.value,
      platform: appType.value as 'web' | 'pwa',
      moduleId: mission.moduleId.toString(),
      grainId: task.grainId.toString()
    }

    const xAPIMessage = xAPIMessageBuilder.createInitializedLearningGrainMessage(input)

    await addToQueue(xAPIMessage)
    taskContent.value = task
  }

  const completeTask = async (courseId: number, mission: Mission, task: Task) => {
    const input = {
      actorUUID: me.value.identifier as UUID,
      courseId: courseId.toString(),
      contextRegistrationUUID: registrationCtx.value,
      platform: appType.value as 'web' | 'pwa',
      moduleId: mission.moduleId.toString(),
      grainId: task.grainId.toString()
    }

    const xAPIMessage = xAPIMessageBuilder.createCompletedLearningGrainMessage(input)

    await addToQueue(xAPIMessage)
  }

  const startMission = async (courseId: number, mission: Mission) => {
    if (mission.order === 1) {
      await startExploration(courseId)
    }

    const input = {
      actorUUID: me.value.identifier as UUID,
      courseId: courseId.toString(),
      contextRegistrationUUID: registrationCtx.value,
      platform: appType.value as 'web' | 'pwa',
      moduleId: mission.moduleId.toString()
    }

    const xAPIMessage = xAPIMessageBuilder.createInitializedLearningModuleMessage(input)

    await addToQueue(xAPIMessage)
  }

  const completeMission = async (courseId: number, mission: Mission) => {
    const input = {
      actorUUID: me.value.identifier as UUID,
      courseId: courseId.toString(),
      contextRegistrationUUID: registrationCtx.value,
      platform: appType.value as 'web' | 'pwa',
      moduleId: mission.moduleId.toString()
    }

    const xAPIMessage = xAPIMessageBuilder.createCompletedLearningModuleMessage(input)

    await addToQueue(xAPIMessage)
  }

  const startExploration = async (courseId: number) => {
    const input = {
      actorUUID: me.value.identifier as UUID,
      courseId: courseId.toString(),
      contextRegistrationUUID: registrationCtx.value,
      platform: appType.value as 'web' | 'pwa'
    }
    const xAPIMessage = xAPIMessageBuilder.createInitializedLearningCourseMessage(input)

    await addToQueue(xAPIMessage)
  }

  const getExplorationNameFromMissionId = (missionId: number) => {
    const exploration = program.value.find((exploration) =>
      exploration.missions.some((mission) => mission.moduleId === missionId)
    )

    return exploration ? exploration.name : undefined
  }

  return {
    program,
    mission,
    taskContent,
    getExplorationNameFromMissionId,
    getProgram,
    getMission,
    getTask,
    startSupport,
    startTask,
    startMission,
    startExploration,
    completeSupport,
    completeTask,
    completeMission
  }
})
