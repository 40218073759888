import API from '@/services/API'
import {
  getLearningGrainResponseDto,
  getLearningModuleResponseDto,
  getProgramResponseDto
} from '@/assets/ts/DTO/trainings/program.response.dto'
import { NabooError } from '@/assets/ts/classes/Error'

export const program = {
  async getCourses() {
    try {
      const response = await API().get('profile/program')

      return Promise.resolve(new getProgramResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/profile/program - getCourses')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération des cours.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  },

  async getModule(courseId: number, moduleId: number) {
    try {
      const response = await API().get(`profile/program/courses/${courseId}/modules/${moduleId}`)

      return Promise.resolve(new getLearningModuleResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/profile/program - getModule')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération du module.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  },

  async getGrain(courseId: number, moduleId: number, grainId: number) {
    try {
      const response = await API().get(
        `profile/program/courses/${courseId}/modules/${moduleId}/grains/${grainId}`
      )

      return Promise.resolve(new getLearningGrainResponseDto(response.data))
    } catch (error) {
      let nabooError: NabooError

      if (error instanceof NabooError) {
        error.setStack('API/profile/program - getGrain')
        nabooError = error
      } else {
        nabooError = new NabooError({
          message: 'Une erreur est survenue lors de la récupération du grain.',
          status: 0,
          name: 'Erreur',
          code: 'ERR_UNKNOWN',
          error: error as Error
        })
      }

      return Promise.reject(nabooError)
    }
  }
}
