<script setup lang="ts">
import EProgressBar from '@/components/elements/progressBar/EProgressBar.vue'
import EIcon from '@/components/elements/icons/EIcon.vue'
import EIcons from '@/components/elements/icons/EIcons.vue'
import animationURL from '@/assets/animations/json/mascotte_levitating.json'
import { Vue3Lottie } from 'vue3-lottie'
import { storeToRefs } from 'pinia'
import { useQuizStore } from '@/stores/quiz'
import { default as PTag } from 'primevue/tag'
import { computed } from 'vue'
import MGradeCircle from '@/components/modules/grades/MGradeCircle.vue'
import { default as PButton } from 'primevue/button'

const { diagnostic } = storeToRefs(useQuizStore())

const quizzes = computed(() => diagnostic.value.quizzes.filter((quiz) => quiz.isCompleted))
</script>

<template>
  <e-progress-bar
    :max="1"
    :value="1"
    :colors="{ progress: 'var(--variant-pink)' }"
    :rounded="false"
  />
  <e-icons :load="['Check']" />

  <div class="flex flex-col items-center justify-center gap-4 p-4 flex-grow">
    <div class="w-12 h-12 rounded-full border-3 border-solid border-success p-2">
      <e-icon name="Check" type="svg" style="--fill: var(--context-success)" />
    </div>
    <h2 class="heading large">Test d'entrée terminé</h2>

    <template v-if="false">
      <vue3-lottie
        :animationData="animationURL"
        :loop="true"
        :autoplay="true"
        :rendererSettings="{
          preserveAspectRatio: 'xMidYMid slice'
        }"
      />
      <p>Patience, nous calculons tes résultats...</p>
    </template>

    <template v-else>
      <p class="text-center">
        Merci pour ton aide, tu vas maintenant pouvoir démarrer tes premières missions !
      </p>
      <div
        v-for="(quiz, i) in quizzes"
        :key="i"
        class="border-b border-solid w-full flex justify-between pb-4 mt-5"
      >
        <div class="flex flex-col gap-2 items-start">
          <p-tag
            class="uppercase font-secondary text medium text-orange-900 bg-orange-300 px-2 py-0"
            rounded
          >
            {{ `Mission ${quiz.moduleName}-${i + 1}` }}
          </p-tag>
          <p class="font-bold">{{ quiz.moduleTitle }}</p>
        </div>

        <m-grade-circle :grade="Math.round((quiz.scaled as number) * 5)" />
      </div>

      <p-button
        label="Accéder à mon journal de bord"
        class="mt-auto mb-6"
        severity="light"
        rounded
        @click="$router.push({ name: 'home' })"
      />
    </template>
  </div>
</template>
