<script setup lang="ts">
import { useProgramStore } from '@/stores/program'
import BOrbits from '@/components/backgrounds/BOrbits.vue'
import { storeToRefs } from 'pinia'
import ProgramExploration from '@/views/training/blocks/ProgramExploration.vue'
import { onMounted, ref } from 'vue'
import ProgramExplorationPlaceholder from '@/views/training/blocks/ProgramExplorationPlaceholder.vue'

// Data, props & refs
const { program } = storeToRefs(useProgramStore())
const loading = ref(true)

// Actions
const { getProgram } = useProgramStore()

onMounted(async () => {
  await getProgram()
  loading.value = false
})
</script>

<template>
  <b-orbits />
  <div
    :class="$style.VProgram"
    class="relative w-full h-full px-4 max-w-screen-sm mx-auto overflow-y-auto overflow-x-hidden pb-4 pt-8 flex flex-col gap-8"
  >
    <div>
      <h2 class="brand-heading">Programme</h2>
    </div>

    <template v-if="loading">
      <program-exploration-placeholder
        v-for="i in 2"
        :key="`Exploration_${i}`"
        :exploration="null"
      />
    </template>
    <template v-else>
      <program-exploration
        v-for="exploration in program"
        :key="`Exploration_${exploration.courseId}`"
        :exploration
      />
    </template>
  </div>
</template>

<style module>
.VProgram {
  &::before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: var(--linear-02);
    z-index: -1;
  }
}
</style>
