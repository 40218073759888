import { defineStore, storeToRefs } from 'pinia'
import { useProfileStore } from '@/stores/profile'
import { computed } from 'vue'
import * as Sentry from '@sentry/vue'
import { useEMethodosStore } from '@/stores/emethodos'
import { NabooError } from '@/assets/ts/classes/Error'
import { useAuth0, User } from '@auth0/auth0-vue'

export const useAuthStore = defineStore('Auth', () => {
  // UTILITY FUNCTIONS
  const auth0Client = useAuth0()
  const { getMe } = useProfileStore()
  const { getEmethodoIsEnabled } = useEMethodosStore()

  const { me } = storeToRefs(useProfileStore())

  // ACTIONS
  function $reset() {
    // Do nothing
  }

  function buildLogoutOidcUrl() {
    let baseUrl = `https://${__AUTH0_DOMAIN__}/oidc/logout`
    baseUrl += `?id_token_hint=${auth0Client.idTokenClaims.value?.__raw}`
    baseUrl += `&post_logout_redirect_uri=${window.location.origin}`
    return baseUrl
  }

  /**
   * Execute the login process after the response of Auth0
   */
  async function initLogin() {
    try {
      await getMe()
      await getEmethodoIsEnabled()
      Sentry.setUser({ id: me.value.identifier } as Sentry.User)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async function initLogout() {
    try {
      if (!auth0Client.isAuthenticated.value) return Promise.resolve()
      await auth0Client.logout({
        openUrl() {
          window.location.replace(buildLogoutOidcUrl())
        }
      })
    } catch (error) {
      const nabooError = new NabooError(
        'Une erreur est survenue lors de la déconnexion.',
        0,
        'Erreur',
        'ERR_UNKNOWN',
        'stores/auth.ts - auth.logout'
      )
      Sentry.captureException({ error, nabooError })
      return Promise.reject(error)
    } finally {
      localStorage.clear()
      Sentry.setUser(null)
    }
    return Promise.resolve()
  }

  const checkSession = async () => await auth0Client.checkSession()

  const loginWithRedirect = async () => await auth0Client.loginWithRedirect()

  const handleRedirectCallback = async () => await auth0Client.handleRedirectCallback()

  const getAccessTokenSilently = async () => await auth0Client.getAccessTokenSilently()

  const isAuthenticated = computed(() => auth0Client.isAuthenticated.value)

  const isLoading = computed(() => auth0Client.isLoading.value)

  const userAuth0 = computed(() => auth0Client.user.value as User)

  const getRoles = computed(
    () => (userAuth0.value?.[`${__AUTH0_DOMAIN__}/roles`] as string[]) ?? ([] as string[])
  )

  // GETTERS (COMPUTED)
  const isAdmin = computed(() => {
    return isAuthenticated.value ? getRoles.value.includes('ADMIN') : false
  })

  const isStudent = computed(() => {
    return isAuthenticated.value ? getRoles.value.includes('STUDENT') : false
  })

  return {
    $reset,
    initLogin,
    initLogout,
    checkSession,
    loginWithRedirect,
    handleRedirectCallback,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    userAuth0,
    isStudent,
    isAdmin
  }
})
