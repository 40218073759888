<script setup lang="ts">
import { default as PTag } from 'primevue/tag'
import BOrbits from '@/components/backgrounds/BOrbits.vue'
import MProgress from '@/components/modules/progress/MProgress.vue'
import { storeToRefs } from 'pinia'
import { useProgressStore } from '@/stores/progress'
import ToggleMissions from '@/views/results/blocks/ToggleMissions.vue'
import { ref } from 'vue'
import MStarScore from '@/components/modules/grades/MStarScore.vue'
import MStarGrades from '@/components/modules/grades/MStarGrades.vue'
import EImproveScoreButton from '@/components/elements/buttons/EImproveScoreButton.vue'
import MissionScoreBlock from '@/views/results/blocks/MissionScoreBlock.vue'

const { globalProgress, testsToPass, missionsInProgress } = storeToRefs(useProgressStore())

const showPerfectScoreMissions = ref(false)
</script>

<template>
  <b-orbits />
  <div
    :class="$style.VResults"
    class="relative w-full h-full px-4 max-w-screen-sm mx-auto overflow-auto pb-4 pt-8 flex flex-col gap-4"
    v-if="!loading"
  >
    <h2 class="brand-heading">Rapport d'activité</h2>

    <m-progress
      :completed="globalProgress.completed"
      :inProgress="globalProgress.inProgress"
      :percentage="globalProgress.progress"
    />

    <div class="grid grid-cols-1 gap-4">
      <h3 class="brand-heading medium">Mes missions terminées</h3>
      <toggle-missions
        v-model:toggle="showPerfectScoreMissions"
        :completed-missions-count="2"
        :perfect-score-missions-count="1"
      />

      <template v-if="showPerfectScoreMissions">
        <mission-score-block score-is-perfect />
      </template>

      <template v-else>
        <mission-score-block />
      </template>
    </div>
  </div>
</template>

<style module>
.VResults {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: var(--linear-02);
    z-index: -1;
  }
}
</style>
