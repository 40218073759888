import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

export const primeVuePreset = definePreset(Aura, {
  primitive: {
    lavender: {
      500: '#512FDA'
    }
  },
  semantic: {
    secondary: {
      500: '{ lavender.500 }'
    }
  }
})
