<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import TaskSupport from '@/views/training/blocks/TaskSupport.vue'
import MPageNavigator from '@/components/modules/pageNavigator/MPageNavigator.vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useConfirm } from 'primevue/useconfirm'

import { useProfileStore } from '@/stores/profile'
import { animateNabizKey } from '@/assets/ts/types/EventBusTypes'
import { useEventBus } from '@vueuse/core'
import { useProgramStore } from '@/stores/program'
import TaskHeader from '@/views/training/blocks/TaskHeader.vue'
import TaskSupportIntro from '@/views/training/blocks/TaskSupportIntro.vue'
import ENavigationButton from '@/components/elements/buttons/ENavigationButton.vue'
import TaskSupportMobileBlock from '@/views/training/blocks/TaskSupportMobileBlock.vue'
import TaskSupportEnd from '@/views/training/blocks/TaskSupportEnd.vue'

const route = useRoute()
const router = useRouter()
const profileStore = useProfileStore()
const eventBus = useEventBus(animateNabizKey)

const { mission, taskContent } = storeToRefs(useProgramStore())

const loading = ref(true)
const showSupport = ref(false)
const showEnd = ref(false)
const showRating = ref(false)
const currentSupportIndex = ref(0)
const nextTimer: Ref<number | undefined> = ref()
const canNext = ref(false)
const isMobileInLandscape = ref(false)

window.addEventListener(
  'orientationchange',
  () => {
    isMobileInLandscape.value = window.orientation !== 0
  },
  true
)

onMounted(async () => {
  await getTask(
    Number(route.params.courseId),
    Number(route.params.moduleId),
    Number(route.params.grainId)
  )

  // Set data
  canNext.value = taskContent.value.supports[currentSupportIndex.value].isWatched

  isMobileInLandscape.value = window.screen.orientation.type.includes('landscape')

  loading.value = false
})

onBeforeUnmount(() => {
  clearTimeout(nextTimer.value)
})

// Computed
const getCurrentDisplayedSupport = computed(() => {
  return taskContent.value.supports[currentSupportIndex.value]
})

// METHODS
const { getTask, startSupport, completeSupport } = useProgramStore()

const start = () => {
  startSupport(
    Number(route.params.courseId),
    mission.value,
    taskContent.value,
    taskContent.value.supports[currentSupportIndex.value]
  )

  nextTimer.value = setTimeout(
    () => {
      canNext.value = true
      clearTimeout(nextTimer.value)
    },
    taskContent.value.supports[currentSupportIndex.value].estimatedTime * 60 * 1000 * 0.5
  )
  showSupport.value = true
}

const navigateToMission = () => {
  router.push({
    name: 'mission',
    params: { courseId: route.params.courseId, missionId: route.params.missionId }
  })
}

async function previous() {
  if (currentSupportIndex.value === 0) return
  currentSupportIndex.value--
  await startSupport(
    Number(route.params.courseId),
    mission.value,
    taskContent.value,
    getCurrentDisplayedSupport.value
  )
  canNext.value = getCurrentDisplayedSupport.value.isWatched
}

async function next() {
  await completeSupport(
    Number(route.params.courseId),
    mission.value,
    taskContent.value,
    taskContent.value.supports[currentSupportIndex.value]
  )

  if (currentSupportIndex.value < taskContent.value.supports.length - 1) {
    currentSupportIndex.value++

    await startSupport(
      Number(route.params.courseId),
      mission.value,
      taskContent.value,
      taskContent.value.supports[currentSupportIndex.value]
    )
  } else {
    // handle last support
    showSupport.value = false
    showEnd.value = true
  }
}
onBeforeRouteLeave((to, from, next) => {
  next()
})
</script>

<template>
  <div v-if="!loading" class="h-full w-full flex flex-col bg-primary-night-blue">
    <task-header v-if="!showSupport || !isMobileInLandscape" :title="taskContent.title" />

    <task-support-mobile-block v-if="showSupport && !isMobileInLandscape" />

    <task-support-end
      :is-mobile-in-landscape="isMobileInLandscape"
      v-if="showEnd"
      :task="taskContent"
    />

    <task-support-intro
      v-if="!showSupport && !showEnd"
      :show-support="showSupport"
      :is-mobile-in-landscape="isMobileInLandscape"
      :start="start"
    />

    <div
      v-if="showSupport"
      class="flex relative h-full"
      :class="isMobileInLandscape ? '' : 'flex-col'"
    >
      <div v-if="isMobileInLandscape" class="p-4 max-w-54 flex flex-col justify-between">
        <div>
          <h5 class="brand-heading medium">{{ taskContent?.title }}</h5>
        </div>

        <m-page-navigator
          ref="pageNavigatorRef"
          :completed="
            taskContent.supports
              .map((support) => (support.isWatched ? support.supportOrder : -1))
              .filter((support) => !!support)
          "
          :total-pages="taskContent.supports.length"
          :current-page="taskContent.supports[currentSupportIndex].supportOrder"
          :canNext="taskContent.supports[currentSupportIndex].isWatched || canNext"
          @next="next()"
          @previous="previous()"
        />
      </div>

      <div class="flex-grow relative">
        <task-support
          v-for="support in taskContent.supports"
          :key="`unit_${support.supportId}`"
          :currentSupport="currentSupportIndex + 1"
          :support="support"
          :show-support="showSupport"
        />
      </div>

      <e-navigation-button
        v-if="isMobileInLandscape"
        class="self-start mt-4 mx-2"
        icon="Close"
        :action="navigateToMission"
      />
      <m-page-navigator
        v-else
        ref="pageNavigatorRef"
        :completed="
          taskContent.supports
            .map((support) => (support.isWatched ? support.supportOrder : -1))
            .filter((support) => !!support)
        "
        :total-pages="taskContent.supports.length"
        :current-page="taskContent.supports[currentSupportIndex].supportOrder"
        :canNext="taskContent.supports[currentSupportIndex].isWatched || canNext"
        :untilTimeInSecs="timerLeftInSecs"
        @next="next()"
        @previous="previous()"
      />
    </div>
  </div>

  <div v-if="false" class="Grain" :class="[isMobileInLandscape ? 'Grain--landscape' : '']">
    <div v-if="isMobileInLandscape" class="Grain-Sidebar">
      <div>
        <h5 class="brand-heading medium">{{ taskContent?.title }}</h5>
      </div>

      <!--      <p-button-->
      <!--        @click="terminate"-->
      <!--        :disabled="disableBtnTerminate"-->
      <!--        v-if="currentSupportIsLast && !currentCompletingTrainingGrain?.completed"-->
      <!--        class="next"-->
      <!--        label="Terminer"-->
      <!--        rounded-->
      <!--      />-->
    </div>

    <template v-else>
      <task-support
        v-for="support in taskContent.supports"
        :key="`unit_${support.supportId}`"
        :currentSupport="1"
        :support="support"
        :show-support="showSupport"
      />
    </template>
  </div>
</template>
