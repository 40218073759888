import type { IProgressQuiz } from '@/assets/ts/types/progress/Progress'

export class GetLogbookInfosResponseDto {
  general: {
    progress: number
    completed: number
    inProgress: number
  }
  quiz?: any[]
  missions: any[]

  constructor(obj: {
    general: {
      progress: number
      completed: number
      inProgress: number
    }
    quiz?: IProgressQuiz[]
    missions: any[]
  }) {
    this.general = obj.general
    this.quiz = obj.quiz
    this.missions = obj.missions
  }
}
