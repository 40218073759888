<script lang="ts" setup>
import { default as PInputText } from 'primevue/inputtext'
import { default as PInputGroup } from 'primevue/inputgroup'
import { default as PButton } from 'primevue/button'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import { useEMethodosStore } from '@/stores/emethodos'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import VideoItem from '@/views/emethodos/blocks/VideoItem.vue'

//Router
const router = useRouter()

// Store
const { getVideos, getVideosByTags } = storeToRefs(useEMethodosStore())
const { setCurrentVideo, getAllEmethodoVideos } = useEMethodosStore()

// Data
const search = ref('')
const carouselRef = ref(null)
const slideWidth = ref(0)
const activeSearch = ref('')
const loading = ref(true)

// Computed
const filteredVideos = computed(() => {
  return getVideos.value.filter((video) => {
    return video.title.toLowerCase().includes(activeSearch.value.toLowerCase())
  })
})

const marginLeft = computed(() => {
  return `margin-left: calc(${slideWidth.value}px + 1rem)`
})

// Lifecycle hooks
onMounted(async () => {
  await getAllEmethodoVideos()
  slideWidth.value = window.innerWidth / 8

  window.addEventListener('resize', () => {
    slideWidth.value = window.innerWidth / 8
  })

  loading.value = false
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', () => {
    slideWidth.value = window.innerWidth / 8
  })
})

// Methods
function handleSearch() {
  if (search.value) {
    activeSearch.value = search.value
  } else {
    activeSearch.value = ''
  }
}

async function selectVideo(videoId: string) {
  await setCurrentVideo(videoId)
  await router.push({ name: 'video' })
}
</script>

<template>
  <div
    :class="[$style['v-contents']]"
    class="flex flex-col items-center py-6 lg:py-20 overflow-x-hidden"
  >
    <h1 class="text-center px-2">Découvrez les tutos de Naboo</h1>

    <h5 class="my-8 text-center">
      Une bibliothèque de vidéos pour approfondir vos méthodes de travail.
    </h5>

    <p-input-group :class="[$style['InputContainer']]" class="mb-6 lg:mb-20 px-2 flex">
      <i class="pi pi-search z-20" />
      <p-input-text
        v-model="search"
        placeholder="Rechercher une vidéo, appuyez sur entrée pour valider"
        :class="[$style['InputSearch']]"
        @keyup.enter="handleSearch()"
      />
      <PButton
        v-if="search"
        icon="pi pi-times"
        severity="secondary"
        @click="
          () => {
            search = ''
            activeSearch = ''
          }
        "
      />
    </p-input-group>
    <template v-if="!loading">
      <template v-if="activeSearch">
        <div class="self-start ml-2 lg:ml-16 mt-12">
          <p-button
            label="Retour à la liste"
            icon="pi pi-arrow-left"
            iconPos="left"
            rounded
            outlined
            severity="secondary"
            style="border-color: #fff; color: #fff"
            @click="
              () => {
                activeSearch = ''
                search = ''
              }
            "
          />
        </div>

        <h4 class="self-start mx-2 mt-12 lg:mx-16">
          Résultat(s) de recherche pour "{{ activeSearch }}":
        </h4>

        <div class="flex flex-wrap px-2 lg:mx-16 w-full lg:px-16">
          <template v-if="filteredVideos.length">
            <video-item
              v-for="(video, i) in filteredVideos"
              :key="`video_${i}`"
              :video="video"
              @click="selectVideo(video.id)"
              search
            />
          </template>
          <p v-else class="mt-8">Il n'y a pas de résultat pour ta recherche.</p>
        </div>
      </template>
      <template v-else>
        <div v-for="(tag, i) in getVideosByTags" :key="`tag_${i}`" class="w-full my-8">
          <template v-if="tag.videos.length > 1">
            <div class="flex items-center mb-4 md:mb-12">
              <h3 :style="marginLeft">
                {{ tag.tag }}
              </h3>
            </div>

            <div class="w-full flex">
              <carousel
                :items-to-show="1.5"
                wrap-around
                class="w-full"
                ref="carouselRef"
                :breakpoints="{
                  768: {
                    itemsToShow: 4
                  }
                }"
              >
                <template #slides>
                  <slide
                    v-for="video in tag.videos"
                    :key="video.id"
                    @click="selectVideo(video.id)"
                    class="cursor-pointer"
                  >
                    <div class="carousel__item">
                      <video-item :video="video" />
                    </div>
                  </slide>
                </template>

                <template #addons>
                  <navigation />
                </template>
              </carousel>
            </div>
          </template>
        </div>
      </template>
    </template>

    <template v-else-if="!getVideosByTags.length">
      <p-button label="recharger les vidéos" @click="getAllEmethodoVideos" :loading="loading">
      </p-button>
    </template>
  </div>
</template>

<style lang="scss" module>
.v-contents {
  height: 100%;
  width: 100%;
  background: url('@/assets/images/backgroundAssets/general/Dark_01.svg');
  background-size: cover;
  color: var(--variant-white);

  &.active {
    padding-left: 5rem;
    padding-right: 5rem;

    @media screen and (max-width: 768px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      .Categories {
        * {
          font-size: 0.6rem;
        }
      }
    }
  }

  .InputContainer {
    position: relative;
    max-width: 50%;
    width: 50rem;
    margin-top: 3rem;

    > i {
      position: absolute;
      color: var(--text-color);
      top: 50%;
      left: 1.5rem;
      transform: translateY(-50%);
    }

    &.active {
      max-width: 100%;
      width: 100%;
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .InputSearch {
    width: 100%;
    padding-left: 2.5rem;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 4rem;

    @media screen and (max-width: 768px) {
      height: 3rem;
    }
  }

  .SearchVideoItem {
    transition: all ease 0.3s;
    aspect-ratio: 16/9;

    img {
      transition: all ease 0.3s;
    }
  }

  .VideosGrid {
    max-width: 1400px;
  }

  .VideoItem {
    transition: all ease 0.3s;
    aspect-ratio: 16/9;
    position: absolute;
    width: 30%;
    height: 100%;
    min-height: 200px;

    img {
      transition: all ease 0.3s;
    }
    &:hover {
      transform: translate(0, -0.5rem);

      img {
        filter: brightness(50%);
        transform: scale(1.1);
      }
    }
  }

  .VideoFooter {
    background-color: var(--primary-dark-blue);
    min-height: 6rem;
  }
}
</style>

<style>
.carousel__item {
  width: 100%;
  background-color: transparent;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  aspect-ratio: 16/9;
  position: relative;
}

.carousel__slide {
  padding: 1rem;

  @media screen and (max-width: 768px) {
    padding: 0.25rem;
  }
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  width: calc((25% / 2) - 1rem) !important;
  height: calc(100% - 2rem) !important;
  margin: 0 !important;
  color: white !important;
  --vc-icn-width: 4rem !important;
  --vc-icn-height: 4rem !important;

  @media screen and (max-width: 768px) {
    width: calc((25% / 2) - 0.125rem) !important;
    height: calc(100% - 0.5rem) !important;
  }
}

.carousel__prev {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  background: linear-gradient(
    -90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 40%,
    rgba(0, 0, 0, 0.8) 100%
  ) !important;
}

.carousel__next {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 40%,
    rgba(0, 0, 0, 0.8) 100%
  ) !important;
}
</style>
