import type { ITrackingConsentBannerInput } from '@/assets/ts/types/UserType'

export class PutTrackingConsentBannerDto implements ITrackingConsentBannerInput {
  hasAnsweredTrackingConsentBanner: boolean
  hasConsentedForTracking: boolean

  constructor() {
    this.hasAnsweredTrackingConsentBanner = true
    this.hasConsentedForTracking = true
  }
}
