<script setup lang="ts">
import { computed, useCssModule } from 'vue'

const props = withDefaults(
  defineProps<{
    value: number
    max: number
    rounded: boolean
    colors?: {
      bg?: string
      progress?: string
    }
  }>(),
  {
    rounded: true,
    colors: () => ({
      bg: 'var(--secondary)',
      progress: 'var(--primary-lavender)'
    })
  }
)

const style = useCssModule()

const progressBarClasses = computed(() => {
  return {
    [style.EProgressBar]: true,
    [style.rounded]: props.rounded
  }
})

const progressBarStyles = computed(() => {
  return {
    '--progress': `${(props.value / props.max) * 100}%`,
    '--progressBarBg': props.colors.bg,
    '--progressBar': props.colors.progress
  }
})
</script>

<template>
  <div :class="progressBarClasses" :style="progressBarStyles" />
  <slot name="content"> </slot>
</template>

<style module>
.EProgressBar {
  --progressBarBg: var(--secondary);
  --progressBar: var(--primary-lavender);
  --progress: 0;
  position: relative;
  height: 10px;
  width: 100%;
  background-color: var(--progressBarBg);

  &::before {
    content: '';
    display: block;
    position: relative;
    width: var(--progress);
    height: 100%;
    background-color: var(--progressBar);
  }

  &.rounded {
    border-radius: 0.5rem;

    &::before {
      border-radius: 0.5rem;
    }
  }
}
</style>
