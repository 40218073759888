import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import type { Ref } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import nabooApi from '@/services/nabooApi'
import type { IVideo } from '@/assets/ts/types/EMethodos'

export const useEMethodosStore = defineStore('EMethodos', () => {
  //STATE
  const currentVideo = useLocalStorage('currentVideo', '0')
  const videos: Ref<IVideo[]> = ref([] as IVideo[])
  const isEnabled = useLocalStorage('eMethodosIsEnabled', false)

  // ACTIONS
  async function setCurrentVideo(videoId: string) {
    currentVideo.value = videoId
  }

  function resetCurrentVideo() {
    currentVideo.value = '0'
  }

  function $reset() {
    resetCurrentVideo()
    videos.value = [] as IVideo[]
    isEnabled.value = false
  }

  async function getAllEmethodoVideos() {
    try {
      const res = await nabooApi.getEmethodos()
      if (res?.status === 200) {
        if (isEnabled.value) {
          videos.value = res.data.data
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function getEmethodoIsEnabled() {
    try {
      const res = await nabooApi.getEmethodoIsEnabled()
      if (res?.status === 200) {
        isEnabled.value = res.data.enabled
      }
    } catch (error) {
      console.error(error)
    }
  }

  // COMPUTED
  const getVideos = computed(() => {
    return videos.value
  })

  const getVideo = computed(() => {
    return getVideos.value.find((video: any) => video.id === currentVideo.value)
  })

  const getVideosByTags = computed(() => {
    return getVideos.value
      .map((video) => video.tags)
      .flat()
      .filter((tag, index, self) => self.indexOf(tag) === index)
      .map((tag) => {
        return {
          tag,
          // Randomize order of videos
          videos: getVideos.value
            .filter((video) => video.tags.includes(tag))
            .sort(() => Math.random() - 0.5)
        }
      })
  })

  return {
    //STATE
    isEnabled,
    currentVideo,

    // ACTIONS
    getAllEmethodoVideos,
    setCurrentVideo,
    resetCurrentVideo,
    getEmethodoIsEnabled,
    $reset,

    // GETTERS
    getVideos,
    getVideo,
    getVideosByTags
  }
})
