<script setup lang="ts">
import MGradeCircle from '@/components/modules/grades/MGradeCircle.vue'
import MStarGrades from '@/components/modules/grades/MStarGrades.vue'
import { default as PTag } from 'primevue/tag'
import { computed, useCssModule } from 'vue'
import EIcons from '@/components/elements/icons/EIcons.vue'

const styles = useCssModule()
const props = defineProps<{
  misionScorePerfect: boolean
}>()

const classes = computed(() => {
  const classes = []

  if (props.misionScorePerfect) {
    classes.push(styles.hasPerfectScore)
  }

  return classes
})
</script>

<template>
  <e-icons :load="['Crown']" />

  <div
    class="rounded-2xl bg-blue-800 p-4 flex flex-col items-center gap-4 relative"
    :class="[$style.MisionResultDetails, classes]"
  >
    <div
      class="rounded-2xl bg-blue-800 w-10 h-10 absolute top-0 left-1/2 p-1"
      :class="$style.Crown"
    >
      <svg class="w-full h-full" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M42.66 13.5619C41.4025 13.5602 40.2585 14.2908 39.7353 15.4291C39.2121 16.5673 39.4037 17.9051 40.2266 18.851C37.9511 20.2028 35.2199 20.5752 32.6623 19.8816C30.1065 19.1898 27.9425 17.4921 26.6694 15.1785C28.1043 14.6103 28.9308 13.1084 28.6417 11.5995C28.3508 10.0907 27.0242 9 25.4794 9C23.9346 9 22.6079 10.0907 22.3171 11.5995C22.0262 13.1084 22.8545 14.6103 24.2893 15.1785C23.0177 17.5116 20.8433 19.2234 18.2715 19.9186C15.698 20.614 12.9506 20.2328 10.6681 18.8615C11.6507 17.7144 11.6968 16.0432 10.7816 14.8449C9.86647 13.6449 8.23474 13.2425 6.862 13.8778C5.49102 14.5114 4.74789 16.0115 5.07775 17.4815C5.40764 18.9516 6.72188 19.9928 8.23474 19.9858C8.37308 19.984 8.51142 19.9752 8.64799 19.9593L11.2853 40H39.6097L42.2471 19.9363C42.3836 19.9521 42.522 19.961 42.6603 19.9627C43.5347 19.9963 44.3843 19.6733 45.0139 19.0697C45.6435 18.4662 46 17.6332 46 16.7614C46 15.8913 45.6435 15.0584 45.0139 14.4548C44.3843 13.8513 43.5344 13.5284 42.66 13.5619Z"
          fill="url(#paint0_linear_4999_36294)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_4999_36294"
            x1="36.8742"
            y1="46.5"
            x2="30.3422"
            y2="2.79605"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF7C4F" />
            <stop offset="0.893293" stop-color="#FBDE68" />
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div class="flex items-center gap-4">
      <div class="flex flex-col items-center gap-2">
        <p class="font-bold">Test d'entrée</p>
        <p class="font-italic">10/09/2024</p>
        <m-grade-circle :grade="1" />
        <p-tag rounded class="text-red-900">1 sur 5</p-tag>
      </div>

      <i class="pi pi-arrow-right text-3xl text-white" />

      <div class="flex flex-col items-center gap-2">
        <p class="font-bold">Fin de la mission</p>
        <p class="font-italic">10/09/2024</p>
        <m-star-grades :points="3" size="small" />
        <p-tag rounded class="text-purple-900">3 sur 5</p-tag>
      </div>
    </div>

    <p>Tu as progressé de <span class="font-bold">+ 2 points</span></p>
  </div>
</template>

<style module>
.MisionResultDetails.hasPerfectScore {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 0.5rem);
    height: calc(100% + 0.5rem);
    background: var(--linear-03);
    z-index: -1;
    border-radius: 1rem;
  }
}

.Crown {
  transform: translate(-50%, -50%);

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: var(--surface-blue-500);
    border-radius: 50%;
    z-index: -1;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + 0.5rem);
    height: calc(100% + 0.5rem);
    background: var(--linear-03);
    border-radius: 50%;
    z-index: -2;
  }
}
</style>
