<script setup lang="ts">
import EIcon from '@/components/elements/icons/EIcon.vue'
import EIcons from '@/components/elements/icons/EIcons.vue'
import { computed } from 'vue'

const props = defineProps<{
  points: number
  large?: boolean
}>()

const classes = computed(() => ({
  'w-4 h-4': !props.large,
  'w-8 h-8': props.large
}))
</script>

<template>
  <e-icons :load="['ScoreStar']" />
  <div
    class="bg-white border border-solid border-primary-500 flex px-2 py-1 rounded-3xl shadow-xl"
    :class="$style.ScoreStar"
  >
    <div v-for="i in 5" :class="classes" :key="i">
      <e-icon
        name="ScoreStar"
        type="svg"
        style="--fill: var(--starFill); --stroke: var(--starStroke)"
        :class="i <= points ? $style.filled : ''"
      />
    </div>
  </div>
</template>

<style module>
.ScoreStar {
  --starFill: transparent;
  --starStroke: rgba(193, 186, 255, 1);

  .filled {
    --starFill: rgba(101, 85, 245, 1);
  }
}
</style>
