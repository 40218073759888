<script setup lang="ts">
import { default as PButton } from 'primevue/button'
import { onMounted, useCssModule } from 'vue'
import EBackButton from '@/components/elements/buttons/EBackButton.vue'
import ENavigationButton from '@/components/elements/buttons/ENavigationButton.vue'
import { useRoute, useRouter } from 'vue-router'

const style = useCssModule()
const route = useRoute()
const router = useRouter()

defineProps<{
  title: string
}>()

const navigateToMission = () => {
  router.push({
    name: 'mission',
    params: { courseId: route.params.courseId, missionId: route.params.missionId }
  })
}
</script>

<template>
  <div class="flex w-full items-center px-4 py-2" :class="[$style['TaskHeader']]">
    <h4 class="grow text-left">{{ title }}</h4>
    <e-navigation-button icon="Close" :action="navigateToMission" />
  </div>
</template>

<style lang="scss" module>
.TaskHeader {
  background-color: var(--primary-dark-blue);
  color: var(--variant-white) !important;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
  height: 5rem;
  z-index: 10;
  position: relative;
}

.p-button-rounded {
  width: 1.1rem !important;
  height: 1.1rem !important;
  background-color: var(--variant-green) !important;
  color: var(--primary-dark-blue) !important;
  border: none !important;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  position: relative;
  outline: none !important;
  opacity: 1;

  > span {
    font-size: 0.6rem !important;
  }

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 0.3rem);
    height: calc(100% + 0.3rem);
    border: 0.2rem solid var(--variant-green);
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
