<script lang="ts" setup>
import type { IGrain, ISupport } from '@/assets/ts/types/TrainingTypes'
import { computed, ref } from 'vue'
import { default as PButton } from 'primevue/button'
import type { ILearningSupport } from '@/assets/ts/types/learning/learningSupports'
import type { Task } from '@/assets/ts/classes/progress/task'

// IMPORTS
const emits = defineEmits(['start-support'])

// PROPS
const props = defineProps<{
  currentSupport: number
  support: ILearningSupport
  showSupport: boolean
}>()

// DATA & REFS

// COMPUTED
const unitPosition = computed(() => {
  if (props.currentSupport === props.support.supportOrder) {
    return 'left: 50%; transform: translate(-50%, -50%);'
  }

  if (props.currentSupport > props.support.supportOrder) {
    return `left: calc(50% - ${(props.currentSupport - props.support.supportOrder) * 100}vw)`
  }

  if (props.currentSupport < props.support.supportOrder) {
    return `left: calc(50% + ${(props.support.supportOrder - props.currentSupport) * 100}vw)`
  }
  return `left: calc(50% + ${(props.support.supportOrder - props.currentSupport) * 100}vw)`
})
</script>

<template>
  <div :class="$style['Grain-Wrapper']" :style="unitPosition">
    <div :class="$style['Grain-Body']">
      <iframe
        width="100%"
        height="100%"
        :src="support.supportUrl"
        title="Support Naboo"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </div>
</template>

<style module lang="scss">
.Grain-Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  aspect-ratio: 16/9;
  transition: all 0.3s ease;

  .Grain-Body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    background: url('@/assets/images/backgroundAssets/sessions/Dark_2.svg');
    background-size: cover;

    .Button-Show-Support {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
    }
  }
}
</style>
