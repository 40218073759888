<script setup lang="ts">
import { computed, ref } from 'vue'

defineProps<{
  completedMissionsCount: number
  perfectScoreMissionsCount: number
}>()

const toggle = defineModel('toggle', { required: true })

const toggleX = computed(() =>
  toggle.value ? '--toggle-x: -2px;' : '--toggle-x: calc(-100% + 2px);'
)
</script>

<template>
  <div
    :style="toggleX"
    @click.prevent="() => (toggle = !toggle)"
    :class="$style.ToggleMissions"
    class="relative flex justify-around cursor-pointer rounded-full py-5"
  >
    <p class="flex-grow text-center text small font-bold text-primary z-10">
      Terminées ({{ completedMissionsCount }})
    </p>
    <p class="flex-grow text-center text small font-bold text-primary z-10">
      Score maximal ({{ perfectScoreMissionsCount }})
    </p>
  </div>
</template>

<style module>
.ToggleMissions {
  background-color: var(--secondary);
  height: 56px;

  &::before {
    content: '';
    display: block;
    width: 50%;
    height: calc(100% - 4px);
    background-color: var(--variant-white);
    position: absolute;
    transform: translate(var(--toggle-x), -50%);
    top: 50%;
    left: 50%;
    border-radius: 100rem;
    transition: all ease 0.3s;
  }
}
</style>
