export interface IUser {
  identifier?: string
  firstname: string
  lastname: string
  nickname?: string
  username?: string
  email: string
  isEmailVerified: boolean
  gender: Gender.Gender
  roles: Role.Role[]
  birthdate?: Date
  picture?: string
  hasAnsweredTrackingConsentBanner: boolean
  hasConsentedForTracking: boolean
  isBlocked: boolean
}

export interface IUserInput {
  identifier: string
  firstname: string
  lastname: string
  nickname: string
  username: string
  email: string
  gender: Gender.Gender
  roles: Role.Role[]
  birthdate: string
  blocked: boolean
}

export interface ITrackingConsentBannerInput {
  hasAnsweredTrackingConsentBanner: boolean
  hasConsentedForTracking: boolean
}

export namespace Role {
  export enum Role {
    ADMIN = 'ADMIN',
    EDU_ENGINEER = 'EDU_ENGINEER',
    COORDINATOR = 'COORDINATOR',
    TEACHER = 'TEACHER',
    TUTOR = 'TUTOR',
    STUDENT = 'STUDENT'
  }

  export function label(authority: Role) {
    switch (authority) {
      case Role.ADMIN:
        return 'Administrateur'
      case Role.EDU_ENGINEER:
        return 'Ing. Pédagogique'
      case Role.COORDINATOR:
        return 'Coordinateur'
      case Role.TEACHER:
        return 'Professeur'
      case Role.TUTOR:
        return 'Tuteur'
      case Role.STUDENT:
        return 'Étudiant'
      default:
        return 'Non défini'
    }
  }
}

export namespace Gender {
  export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER',
    UNSPECIFIED = 'UNSPECIFIED'
  }

  export function label(gender: Gender) {
    switch (gender) {
      case Gender.MALE:
        return 'Masculin'
      case Gender.FEMALE:
        return 'Féminin'
      case Gender.OTHER:
        return 'Autre'
      case Gender.UNSPECIFIED:
        return 'Non spécifié'
      default:
        return 'Non défini'
    }
  }
}
