<script setup lang="ts">
import RotatePhoneImage from '@/assets/images/Rotate_phone_Portrait.svg'
import { default as PButton } from 'primevue/button'
import { useRoute, useRouter } from 'vue-router'
import type { Task } from '@/assets/ts/classes/progress/task'

const route = useRoute()
const router = useRouter()

const props = defineProps<{
  isMobileInLandscape: boolean
  task: Task
}>()

const goToPostGrainQuiz = () => {
  router.push({
    name: 'quiz',
    params: {
      courseId: route.params.courseId,
      moduleId: route.params.moduleId,
      quizId: props.task.postQuizId
    }
  })
}
</script>

<template>
  <div class="flex justify-center items-center flex-grow bg-surface-blue">
    <div
      class="flex items-center justify-center gap-4"
      :class="{ 'flex-col': !isMobileInLandscape }"
    >
      <template v-if="isMobileInLandscape">
        <img :src="RotatePhoneImage" />

        <div class="flex flex-col items-start max-w-80 gap-4">
          <h3 class="heading large">C'est terminé !</h3>
          <p class="text">
            Tu peux maintenant remettre ton téléphone en mode portrait
            <span class="font-bold">en mode portrait</span>.
          </p>
          <p-button
            label="Je passe à la suite !"
            severity="light"
            rounded
            @click="goToPostGrainQuiz()"
          />
        </div>
      </template>
      <template v-else>
        <h3 class="heading large">C'est terminé !</h3>
        <p-button
          label="Je passe à la suite !"
          severity="light"
          rounded
          @click="goToPostGrainQuiz()"
        />
      </template>
    </div>
  </div>
</template>
