<script setup lang="ts">
import { default as PTag } from 'primevue/tag'

import type { Exploration } from '@/assets/ts/classes/progress/exploration'
import ProgramMissionBlock from '@/views/training/blocks/ProgramMissionBlock.vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import TestBlock from '@/views/logbook/blocks/TestBlock.vue'

const props = defineProps<{
  exploration: Exploration
}>()

const displayedMissionIndex = ref(0)
const startX = ref(0)
const endX = ref(0)
const ExplorationMissions = ref<HTMLElement>()

const explorationTransform = computed(() => {
  return {
    transform: `translateX(-${displayedMissionIndex.value * 14.5}rem)`
  }
})

const handleStart = (position: number) => {
  startX.value = position
  endX.value = position
}

const handleMove = (position: number) => {
  endX.value = position
}

const handleEnd = () => {
  if (startX.value - endX.value > 10) {
    // Swipe or drag left
    displayedMissionIndex.value = Math.min(
      displayedMissionIndex.value + 1,
      props.exploration.missions.length - 1
    )
  } else if (endX.value - startX.value > 10) {
    // Swipe or drag right
    displayedMissionIndex.value = Math.max(displayedMissionIndex.value - 1, 0)
  }
  // Reset start and end positions
  startX.value = 0
  endX.value = 0
}

const handleTouchStart = (event: TouchEvent) => handleStart(event.touches[0].clientX)
const handleTouchMove = (event: TouchEvent) => handleMove(event.touches[0].clientX)
const handleTouchEnd = () => handleEnd()

const handleMouseDown = (event: MouseEvent) => {
  handleStart(event.clientX)
  window.addEventListener('mousemove', handleMouseMove)
  window.addEventListener('mouseup', handleMouseUp)
}

const handleMouseMove = (event: MouseEvent) => handleMove(event.clientX)
const handleMouseUp = () => {
  handleEnd()
  window.removeEventListener('mousemove', handleMouseMove)
  window.removeEventListener('mouseup', handleMouseUp)
}

onMounted(() => {
  ExplorationMissions.value?.addEventListener('touchstart', handleTouchStart)
  ExplorationMissions.value?.addEventListener('touchmove', handleTouchMove)
  ExplorationMissions.value?.addEventListener('touchend', handleTouchEnd)
  ExplorationMissions.value?.addEventListener('mousedown', handleMouseDown)
})

onUnmounted(() => {
  ExplorationMissions.value?.removeEventListener('touchstart', handleTouchStart)
  ExplorationMissions.value?.removeEventListener('touchmove', handleTouchMove)
  ExplorationMissions.value?.removeEventListener('touchend', handleTouchEnd)
  ExplorationMissions.value?.removeEventListener('mousedown', handleMouseDown)
})
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <div class="flex justify-between">
        <p-tag
          class="bg-orange-300 text-orange-900 uppercase text medium px-2 py-0"
          rounded
          :value="'exploration ' + exploration.name"
        />
        <div class="flex gap-2">
          <div
            v-for="i in exploration.missions.length"
            :key="exploration.name + i"
            class="w-4 h-4 rounded-full bg-white"
            :class="displayedMissionIndex < i - 1 ? 'bg-opacity-50' : ''"
          />
        </div>
      </div>
      <h3 class="brand-heading medium uppercase">{{ exploration.title }}</h3>
    </div>

    <test-block :quiz="exploration.quizzes[0]" v-if="exploration.hasTestToPass" />

    <div
      v-else
      ref="ExplorationMissions"
      class="w-full flex gap-2 transition"
      :style="explorationTransform"
    >
      <div v-for="(mission, index) in exploration.missions" :key="index">
        <ProgramMissionBlock
          @click="
            $router.push({
              name: 'mission',
              params: { courseId: exploration.courseId, moduleId: mission.moduleId }
            })
          "
          :mission="mission"
          :exploration="exploration"
        />
      </div>
    </div>
  </div>
</template>
