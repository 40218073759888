import type { NavigationGuardNext, RouteLocationNormalized, Router } from 'vue-router'
import { storeToRefs } from 'pinia'
import slug from 'slug'
import { useEMethodosStore } from '@/stores/emethodos'
import { useHistoryStore } from '@/stores/navigationHistory'

export const clearQueryParamsVersion = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (to.query.v) {
    const query = { ...to.query }
    delete query.v
    next({ name: to.name as string, query: query })
    return
  }
  next()
}

export const updateUrlPath = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { getVideo } = storeToRefs(useEMethodosStore())

  if (to.name === 'video' && getVideo.value?.title && !to.params?.videoTitle) {
    next({
      name: 'video',
      params: {
        videoTitle: slug(getVideo.value.title || '')
      }
    })
    return
  }

  next()
}

/**
 * Used to update the current url path when the user is completing a training session or grain
 */
export const updateCurrentUrlPath = async (router: Router, route: RouteLocationNormalized) => {
  const { getVideo } = storeToRefs(useEMethodosStore())

  if (route.name === 'video' && getVideo.value?.title && !route.params.videoTitle) {
    await router.replace({
      name: 'video',
      params: {
        videoTitle: slug(getVideo.value.title || '')
      }
    })
  }
}

export const isFeatureEnabled = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const { isEnabled } = storeToRefs(useEMethodosStore())

  if (!isEnabled.value) {
    next({ name: 'home' })
    return
  }
  next()
}

export const registerRouteToHistory = (route: RouteLocationNormalized) => {
  const { addToHistory } = useHistoryStore()

  if (route.name === 'quiz') return
  if (route.name === 'task') return
  addToHistory(route.fullPath)
}
