import { Gender, type IUser, Role } from '@/assets/ts/types/UserType'

export class GetMeResponseDto implements IUser {
  identifier?: string
  firstname: string
  lastname: string
  nickname?: string
  username?: string
  email: string
  isEmailVerified: boolean
  gender: Gender.Gender
  roles: Role.Role[]
  birthdate?: Date
  picture?: string
  hasAnsweredTrackingConsentBanner: boolean
  hasConsentedForTracking: boolean
  isBlocked: boolean

  constructor(obj: {
    identifier: string
    firstname: string
    lastname: string
    nickname?: string
    username?: string
    email: string
    isEmailVerified: boolean
    gender: Gender.Gender
    roles: Role.Role[]
    birthdate?: Date
    picture?: string
    hasAnsweredTrackingConsentBanner: boolean
    hasConsentedForTracking: boolean
    isBlocked: boolean
  }) {
    this.identifier = obj.identifier
    this.firstname = obj.firstname
    this.lastname = obj.lastname
    this.nickname = obj.nickname
    this.username = obj.username
    this.email = obj.email
    this.isEmailVerified = obj.isEmailVerified
    this.gender = obj.gender
    this.birthdate = obj.birthdate
    this.picture = obj.picture
    this.gender = obj.gender
    this.roles = obj.roles
    this.hasAnsweredTrackingConsentBanner = obj.hasAnsweredTrackingConsentBanner
    this.hasConsentedForTracking = obj.hasConsentedForTracking
    this.isBlocked = obj.isBlocked
  }
}

export class GetPointsResponseDto {
  points: number

  constructor(obj: { points: number }) {
    this.points = obj.points
  }
}
