<script setup lang="ts">
import { LearningEnums } from '@/assets/ts/types/learning/enums'
import { computed } from 'vue'
import HelpMatchingImage from '@/assets/images/help/quizz/HELP_Matching.png'
import HelpMCQImage from '@/assets/images/help/quizz/HELP_MCQ.png'
import HelpSCQImage from '@/assets/images/help/quizz/HELP_SCQ.png'
import HelpSequencingImage from '@/assets/images/help/quizz/HELP_Sequencing.png'
import HelpTrueFalseImage from '@/assets/images/help/quizz/HELP_True_False.png'
import { default as PImage } from 'primevue/image'
import { default as PButton } from 'primevue/button'

const props = defineProps<{
  type: LearningEnums.QuestionType
}>()

const titleText = computed(() => {
  switch (props.type) {
    case LearningEnums.QuestionType.MULTIPLE_CHOICE:
      return 'Les questions à choix multiples'
    case LearningEnums.QuestionType.SINGLE_CHOICE:
      return 'Les questions à choix unique'
    case LearningEnums.QuestionType.TRUE_FALSE:
      return 'Vrai ou faux'
    case LearningEnums.QuestionType.MATCHING:
      return 'Associer'
    case LearningEnums.QuestionType.SEQUENCING:
      return 'Réordonner'
    default:
      return 'Choix multiples'
  }
})

const text = computed(() => {
  switch (props.type) {
    case LearningEnums.QuestionType.MULTIPLE_CHOICE:
      return 'Pour sélectionner une ou plusieurs réponses, il te suffit de <span class="font-bold">cliquer dessus !</span>'
    case LearningEnums.QuestionType.SINGLE_CHOICE:
      return 'Tu ne peux sélectionner qu’<span class="font-bold">une seule réponse</span> : il te suffit de <span class="font-bold">cliquer dessus !</span>'
    case LearningEnums.QuestionType.TRUE_FALSE:
      return 'Tu ne peux sélectionner qu’<span class="font-bold">une seule réponse</span> : il te suffit de <span class="font-bold">cliquer dessus !</span>'
    case LearningEnums.QuestionType.MATCHING:
      return 'Tu peux glisser une étiquette vers une cible, ou alors cliquer sur l’étiquette puis cliquer sur la cible :'
    case LearningEnums.QuestionType.SEQUENCING:
      return 'Tu peux glisser les étiquettes pour les mettre dans l’ordre que tu souhaites :'
    default:
      return 'Sélectionnez une ou plusieurs réponses.'
  }
})

const subtext = computed(() => {
  switch (props.type) {
    case LearningEnums.QuestionType.MULTIPLE_CHOICE:
      return 'Lorsque ton choix est fait, tu n’as plus qu’à <span class="font-bold">cliquer sur “Valider”</span> !'
    case LearningEnums.QuestionType.SINGLE_CHOICE:
      return 'Lorsque ton choix est fait, tu n’as plus qu’à <span class="font-bold">cliquer sur “Valider”</span> !'
    case LearningEnums.QuestionType.TRUE_FALSE:
      return 'Lorsque ton choix est fait, tu n’as plus qu’à <span class="font-bold">cliquer sur “Valider”</span> !'
    case LearningEnums.QuestionType.MATCHING:
      return 'Lorsque tes étiquettes sont placées, tu n’as plus qu’à <span class="font-bold">cliquer sur “Valider”</span> !'
    case LearningEnums.QuestionType.SEQUENCING:
      return 'Lorsque tes étiquettes sont placées, tu n’as plus qu’à <span class="font-bold">cliquer sur “Valider”</span> !'
    default:
      return 'Sélectionnez une ou plusieurs réponses.'
  }
})

const image = computed(() => {
  switch (props.type) {
    case LearningEnums.QuestionType.MULTIPLE_CHOICE:
      return HelpMCQImage
    case LearningEnums.QuestionType.SINGLE_CHOICE:
      return HelpSCQImage
    case LearningEnums.QuestionType.TRUE_FALSE:
      return HelpTrueFalseImage
    case LearningEnums.QuestionType.MATCHING:
      return HelpMatchingImage
    case LearningEnums.QuestionType.SEQUENCING:
      return HelpSequencingImage
    default:
      return HelpMCQImage
  }
})
</script>

<template>
  <div
    class="absolute top-0 left-0 flex flex-col items-center justify-center gap-8 w-screen h-screen z-50 bg-primary-night-blue text-center bg-opacity-100 p-4 px-6"
  >
    <div class="absolute top-4 right-4">
      <p-button rounded text severity="light" icon="pi pi-times" @click="$emit('close')" />
    </div>

    <div class="flex flex-col justify-center items-center">
      <h3 class="brand-heading medium">Besoin d'aide ?</h3>
      <h2 class="brand-heading large">{{ titleText }}</h2>
    </div>

    <div class="flex flex-col gap-5 justify-center items-center">
      <p class="mt-4 max-w-72" v-html="text" />

      <p-image :src="image" class="w-full mt-4" image-class="w-full" />
    </div>

    <p class="mt-4" v-html="subtext" />
  </div>
</template>
