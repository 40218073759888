<script setup lang="ts">
import { default as PImage } from 'primevue/image'
import CorrectImage from '@/assets/images/mascottes/quiz/MASCOTTE_Quizz_correct.png'
import IncorrectImage from '@/assets/images/mascottes/quiz/MASCOTTE_Quizz_incorrect.png'
import { computed } from 'vue'

const props = defineProps<{
  correct: boolean
}>()

const text = computed(() => {
  return props.correct ? 'Correct !' : 'Pas tout à fait !'
})
</script>

<template>
  <div
    class="absolute top-0 left-0 w-screen h-screen bg-primary-night-blue bg-opacity-85 flex flex-col items-center justify-center z-10"
  >
    <p-image
      class="w-full max-w-72"
      image-class="w-full"
      :src="correct ? CorrectImage : IncorrectImage"
    />
    <p class="heading large">{{ text }}</p>
  </div>
</template>
