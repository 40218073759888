import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useSessionStorage } from '@vueuse/core'

export const useAppStore = defineStore('App', () => {
  // STATE
  const registrationCtx = useSessionStorage('registrationCtx', ref(uuidv4()))
  const userAgent = ref(window.navigator.userAgent)
  const isStandalone = ref(
    'matchMedia' in window && typeof window.matchMedia === 'function'
      ? window.matchMedia('(display-mode: standalone)').matches
      : false
  )

  // GETTERS (COMPUTED)
  const appType = computed(() => {
    return !isStandalone.value ? 'web' : 'pwa'
  })

  // ACTIONS
  function $reset() {
    registrationCtx.value = ''
    userAgent.value = ''
    isStandalone.value = false
  }

  return {
    // STATE
    registrationCtx,
    userAgent,
    isStandalone,

    // GETTERS
    appType,

    // ACTIONS
    $reset
  }
})
