import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '@/router/routes'
import { useHistoryStore } from '@/stores/navigationHistory'
import { onMounted } from 'vue'
import { registerRouteToHistory } from '@/router/utils'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})

onMounted(() => {
  router.afterEach((to) => {
    console.log('router.afterEach', to)
    document.title = `Naboo | ${to.meta.title || 'Accueil'}`
    registerRouteToHistory(to)
  })
})

export default router
